import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptHelperService {
  
   randomKey(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }


  GetDataEncrypted(str : string){
    let indexKey = -1;
    for (let index = 0; index < str.length; index++) {
      if(!isNaN(+str[index])){
        indexKey = index + 1;
        break;
      }
    }
    return {
      data: str.substring(0, indexKey) + str.substring(indexKey + 16),
      key: str.substring(indexKey, indexKey + 16)
    }

  }

  AddKeyToData(str : string, key : string){
    let indexKey = -1;
    for (let index = 0; index < str.length; index++) {
      if(!isNaN(+str[index])){
        indexKey = index + 1;
        break;
      }
    }
    return str.substring(0, indexKey) + key + str.substring(indexKey)
  }


  Encrypt_Data(str : string, key : string){
    let _key = CryptoJS.enc.Utf8.parse(key);
   
    let encrypted = CryptoJS.AES.encrypt(str, _key, {
      keySize: 128,
      iv: null,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return this.AddKeyToData(encrypted.toString(),key) ;

  }

  Decrypt_Data(str : string){

    let data = this.GetDataEncrypted(str) ;

    let _key = CryptoJS.enc.Utf8.parse(data.key);
   
    return CryptoJS.AES.decrypt(decodeURIComponent(data.data) , _key, {
      keySize: 128,
      iv: null,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
  }

  Encrypt_DataUser(str : string){
    let isOk = true; 
    let key = this.randomKey(16);
    let data_encrypted = "";
    while (isOk) {
     let _key = CryptoJS.enc.Utf8.parse(key);
    
     let encrypted = CryptoJS.AES.encrypt(str, _key, {
       keySize: 128,
       iv: null,
       mode: CryptoJS.mode.ECB,
       padding: CryptoJS.pad.Pkcs7,
     });
     data_encrypted = encrypted.toString();
     let indexKey = -1;
     for (let index = 0; index < data_encrypted.length; index++) {
       if(!isNaN(+data_encrypted[index])){
         indexKey = index + 1;
         break;
       }
     }
 
     if(indexKey >-1){
       isOk = false;
     }else{
       key = this.randomKey(16);
     }
 
    }
     return this.AddKeyToData(data_encrypted,key) ;
 
   }

}
