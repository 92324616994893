
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintDonHangService {

  private isPrintDonHangActived = new BehaviorSubject<any>(null);
  PrintDonHangChanges: Observable<any> = this.isPrintDonHangActived.asObservable();
  /**
   *
   */
  constructor(
  ) {
  }

 
  public SendNotiPrintDonHang(data: any) {
    this.isPrintDonHangActived.next(data);
  }
 
  


}
