<div class="custom-modal-backdrop" (click)="closeModal()">
  <div class="hotline" nz-menu (click)="$event.stopPropagation()">
    <div class="hotline-title">
      <div style="font-size: 16px; font-weight: 500;">Tổng đài {{tongDai.tongDaiText}}</div>
      <div class="d-flex">
        <span (click)="reloadHotline()" nz-tooltip="Tải lại danh sách hotline" class="myhub-button-close"
          style="font-size: 20px; margin-right: 10px;" nz-icon nzType="redo" nzTheme="outline"></span>
        <span (click)="onCloseListHotline()" nz-tooltip="Đóng" class="myhub-button-close" style="font-size: 20px;"
          nz-icon nzType="close-circle" nzTheme="outline"></span>
      </div>
    </div>
    <hr>
    <div class="info-phone" *ngIf="dataCall?.To != null">Số điện thoại gọi tới: {{dataCall.To}}</div>
    <table>
      <thead>
        <tr>
          <!-- <th>STT</th> -->
          <th>Số điện thoại gọi đi</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data;  let i=index" (click)="clickToCall(item.hotlineId)">
          <!-- <td>
          <div>{{i+1}}</div>
        </td> -->
          <td style="text-align: start;">
            <div>{{item.stt}} - {{item.network}}<span *ngIf="item.soDienThoai"> - {{item.soDienThoai}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>