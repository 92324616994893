import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../models/response';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_FILE}Files`)
  }

  layThongTinFile(listFile): Promise<ResponseData> {
    const url = `${this.table}/GetsByIds`;
    return this.httpClient.post<ResponseData>(url, listFile).toPromise();
  }

  getFile(id, size = 'm'): Promise<ResponseData> {
    
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  getImage(id, size = 'm'): Observable<Blob> {
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getImageDownload(id, size = 'm'): Observable<Blob> {
    const url = `${this.table}/GetFile?id=${id}&size=${size}`;
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.httpClient.get(url, {headers: headers, responseType: 'blob' });
  }

  uploadImage(file):Observable<ResponseData>{
    let formData = new FormData();
    formData.append('file',file);
    const url = `${this.table}/UploadFile`;
    return this.httpClient.post<ResponseData>(url,formData);
  }
}
