<ng-select [items]="people$ | async" bindLabel="ten"
[hideSelected]="true"
[closeOnSelect]="true"
[trackByFn]="trackByFn"
[loading]="isLoading"
typeToSearchText="Nhập phím [space] để hiển thị đơn vị" [typeahead]="peopleInput$"
[(ngModel)]="selectModel"
(change)="onChangeDonVi($event)"
(clear)="onChangeDonVi($event)"
(remove)="onChangeDonVi($event)"
[searchFn]="customSearchFn"
appendTo="body"
>
</ng-select>
