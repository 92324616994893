import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ResponseData } from '../../models/response';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.SERVICE_API_CONTACT}api/Contact`);
  }

  TimTheoDieuKienAsync(body): Promise<ResponseData> {
    const url = `${this.table}/TimTheoDieuKienAsync`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  GetListContactDatLichChoDuyet(body): Promise<ResponseData> {
    const url = `${this.table}/GetListContactDatLichChoDuyet`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  ChiTietContact(idContact): Promise<ResponseData> {
    const url = `${this.table}/ChiTietContact/${idContact}`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetInfoBasicContact(idContact): Promise<ResponseData> {
    const url = `${this.table}/GetInfoBasicContact/${idContact}`;
    return this.get<ResponseData>(url).toPromise();
  }

  CapNhatKetQuaTacNghiep(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatKetQuaTacNghiep`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  CapNhatChuyenTacNghiep(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatChuyenTacNghiep`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  CapNhatContact(id, body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatContact/${id}`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  InsertContactAsync(body): Promise<ResponseData> {
    const url = `${this.table}/InsertContactAsync`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  Marketing_InsertContact(body): Promise<ResponseData> {
    const url = `${this.table}/Marketing_InsertContact`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  CheckContactTrungAsync(filter): Promise<ResponseData> {
    const url = `${this.table}/CheckContactTrung`;
    return this.post<ResponseData>(url, filter).toPromise();
  }

  PhanBoLaiData(body): Promise<ResponseData> {
    const url = `${this.table}/PhanBoLaiData`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  PhanBoLaiDataNhom(body): Promise<ResponseData> {
    const url = `${this.table}/PhanBoLaiDataNhom`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  DatLichContact(body): Promise<ResponseData> {
    const url = `${this.table}/DatLichContact`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  LayTacNghiepCan(body): Promise<ResponseData> {
    const url = `${this.table}/LayTacNghiepCan`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  DuyetContactDeTaoLich(body): Promise<ResponseData> {
    const url = `${this.table}/DuyetContactDeTaoLich`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  HuyDuyetContactTaoLich(body): Promise<ResponseData> {
    const url = `${this.table}/HuyDuyetContactTaoLich`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  DeleteContact(id): Promise<ResponseData> {
    const url = `${this.table}/DeleteContact/${id}`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  GetDanhSachTrungSo(model): Promise<ResponseData> {
    const url = `${this.table}/GetDanhSachTrungSo`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  GetDsTrungSo(payload): Promise<ResponseData> {
    const url = `${this.table}/GetDsTrungSo`;
    return this.post<ResponseData>(url, payload).toPromise();
  }

  //IMPORT CONTACT
  DownloadFileTemplateImportContact() {
    const url = `${this.table}/DownloadFileTemplateImportContact`;
    return this.getDownload(url);
  }

  DownloadDataWrongContact(id) {
    const url = `${this.table}/DownloadDataWrongContact/${id}`;
    return this.getDownload(url);
  }
  //END IMPORT CONTACT
  UpdateThoiGianTacNghiep(body): Promise<ResponseData> {
    const url = `${this.table}/UpdateThoiGianTacNghiep`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  CapNhatDhLogisticContact(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatDhLogisticContact`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  //#region tin nhắn nội bộ
  SaveNote(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/Contact/SaveNote`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  SaveMessage(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/Contact/SaveMessage`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  GetListMessageByIdContact(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/Contact/GetListMessageByIdContact`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  GetListMessageByPhone(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/Contact/GetListMessageByPhone`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  //#endregion

  //#region Black list
  InsertOrUpdateBlackList(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/Contact/InsertOrUpdateBlackList`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  GetDataByIdContact(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/ContactPhoneBlacklist/GetDataByIdContact`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  DeleteBlackList(body) {
    const url = `${environment.SERVICE_API_CONTACT}api/Contact/DeleteBlackList`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  //#endregion

  //#region Contact Nhan
  CapNhatNhanKhachHang(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatNhanKhachHang`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  //#endregion

  Sale_GetLogTacNghiep(body): Promise<ResponseData> {
    const url = `${environment.SERVICE_API_LOG}api/LichSuTacNghiep/Sale_TimTheoDieuKien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  Kho_GetLogTacNghiep(body): Promise<ResponseData> {
    const url = `${environment.SERVICE_API_LOG}api/LichSuTacNghiep/Kho_TimTheoDieuKien`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  Sale_Kho_GetLogTacNghiep(body): Promise<ResponseData> {
    const url = `${environment.SERVICE_API_LOG}api/LichSuTacNghiep/Sale_Kho_TimTheoDieuKien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  // getImage(id, size = 'm'): Observable<Blob> {
  //     const url = `${this.table}/GetFile?id=${id}&size=${size}`;
  //     return this.httpClient.get(url, { responseType: 'blob' });
  //   }

  ExportExcelContactKieu1(body): Observable<Blob> {
    const url = `${environment.SERVICE_API_CONTACT}api/ContactImport/ExportExcelContactKieu1`;
    return this.httpClient.post<Blob>(url, body, {
      responseType: 'blob' as 'json',
    });
  }

  ChuyenTacNghiepTiep(body): Promise<ResponseData> {
    const url = `${this.table}/ChuyenTacNghiepTiep`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  CreateContactFromHotline(body): Promise<ResponseData> {
    const url = `${this.table}/CreateContactFromHotline`;
    return this.post<ResponseData>(url, body).toPromise();
  }
  UpdateNgayMuonNhanHang(body): Promise<ResponseData> {
    const url = `${this.table}/UpdateNgayMuonNhanHang`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  TimTheoNhan(body): Promise<ResponseData> {
    const url = `${this.table}/TimTheoNhan`;
    return this.post<ResponseData>(url, body).toPromise();
  }
}
