import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment } from '../../../environments/environment';
import { ResponseData } from '../../models/response';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NangCapGoiSuDungService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.CORE_API}api/NangCapGoiSuDung`);
  }

  NangCapSuDung(body): Promise<ResponseData> {
    const url = `${this.table}/NangCapSuDung`;
    return this.PostRequest(url, body);
  }

  GetGoiSuDungHienTai() {
    const url = `${this.table}/GetGoiSuDungHienTai`;
    return this.GetRequest(url);
  }

  GetListGoiSuDung() {
    const url = `${this.table}/GetListGoiSuDung`;
    return lastValueFrom(this.get<ResponseData>(url));
  }

  GetThoiHanGoi() {
    const url = `${this.table}/GetThoiHanGoi`;
    return lastValueFrom(this.get<ResponseData>(url));
  }

  CheckSoLuotApDungMaGioiThieu(): Promise<ResponseData> {
    const url = `${this.table}/CheckSoLuotApDungMaGioiThieu`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }
}
