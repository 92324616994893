<div id="screen-main" class="common-layout {{ 'is-' + selectedHeaderColor }}" [ngClass]="{
        'is-folded': isFolded,
        'is-side-nav-dark' : isSideNavDark,
        'is-expand' : isExpand
    }">
  <app-side-menu></app-side-menu>


  <div class="page-container">
    <app-header></app-header>
    <div class="main-content">
      <sb-menu-tab></sb-menu-tab>
      <!-- <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
            </div> -->
      <router-outlet></router-outlet>
    </div>
    <div cdkDrag [ngStyle]="isActiveOmiCall?{'display':'flex'} : {'display': 'none'}"
      (click)="onChangeShowOmiViewDial()"
      [ngClass]="dataOmiCall?.status == 'invite' ?'omi-button omi-button-ringing' :'omi-button' ">
      <div style="color:white; font-weight: 500;"
        *ngIf="['connected','hold','ringing','invite','connecting'].includes(dataOmiCall?.status);else  iconButton">
        {{ callDuration.format('mm:ss')}}
      </div>
      <ng-template #iconButton>
        <span style="font-size: 20px; color:white" nz-icon nzType="phone" nzTheme="outline"></span>
      </ng-template>
    </div>

    <app-myhub-view-hotline class="myhub-view"
      [ngStyle]="isShowMyHub?{'display':'inline-block','z-index': '1000'} : {'display': 'none'}">
    </app-myhub-view-hotline>
    <app-omicall-view-dial cdkDrag [dataOmiCall]="dataOmiCall"
      [ngStyle]="isShowOmiViewDial && isActiveOmiCall?{'display':'inline-block','z-index': '2147483647'} : {'display': 'none'}"
      class="omi-view"></app-omicall-view-dial>

    <app-footer></app-footer>
    <!-- <icon-guide></icon-guide> -->
  </div>

</div>
<!-- <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
  <p style="font-size: 20px; color: white">Đang tải...</p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.1)" [fullScreen]="false" type="ball-scale-multiple" size="medium"
  template="<img style='width:150px' src='assets/gif/loading.gif' />">
</ngx-spinner>