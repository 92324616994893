import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {
  private apiUrl = `${environment.SERVICE_API_LANDING}api/texttospeech/speak`; // Thay đổi URL theo cấu hình API của bạn

  constructor(private http: HttpClient) { }

  getSpeech(text: string): Observable<Blob> {
    const params = new HttpParams()
      .set('text', text);

    return this.http.get(this.apiUrl, { params, responseType: 'blob' });
  }
}

  