import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { REGEX } from 'src/app/constants/config';
import { MESSAGE } from 'src/app/constants/message';
import { ResponseData } from 'src/app/models/response';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/core/user.service';
import { CryptHelperService } from 'src/app/services/cryptohelper.service';

@Component({
  selector: 'app-chon-tk',
  templateUrl: './chon-tk.component.html',
  styleUrls: ['./chon-tk.component.scss']
})

export class ChonTkComponent implements OnInit {
  datas: any;
  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private _userService: UserService,
    private _userProfileService: UserService,
    private spinner: NgxSpinnerService,
    private _notification: NzNotificationService,
    private authService: AuthService,
    private _cryptHelperService: CryptHelperService,
  ) {
  }

  ngOnInit() {

  }

  onChon(data) {
    this.modal.close(data);
  }
  onThoat() {
    this.modal.close(false);
  }

}