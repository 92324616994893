export enum SwitchBoardCode {
  OmiCall = 'omicall',
  MyHub = 'myhub',
}
export const SwitchBoardId: Array<any> = [
  {
    id: 1,
    name: 'MyHub'
  },
  {
    id: 2,
    name: 'OmiCall'
  },
]

export enum OmiCallViewType {
  DIAL = 'DIAL',
  CALLING = 'CALLING',
  IN_COMMING = 'IN_COMMING',
  OUT_COMMING = 'OUT_COMMING',
}

export enum OmiCallStatus {
  connecting = 'connecting',
  ringing = 'ringing',
  connected = 'connected',
  ended = 'ended',
  hold = 'hold',
  invite = 'invite'
}
