import { Directive } from "@angular/core";
import { NzSelectComponent } from "ng-zorro-antd/select";

@Directive({
    selector: 'nz-select'  // Tự động áp dụng cho tất cả nz-select
  })
  export class NzSelectDefaultDirective {
    constructor(private nzSelect: NzSelectComponent) {
      this.nzSelect.nzDropdownMatchSelectWidth = false;
    }
  }