import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ResponseDTO, ResponseData } from 'src/app/models/response';
import { UserLogin } from 'src/app/models/userlogin';
import { UserService } from 'src/app/services/core/user.service';
import { KhachHangService } from 'src/app/services/customer/khachhang.service';
import { CauHinhContactService } from 'src/app/services/landing/cau-hinh.service';
import { ContactService } from 'src/app/services/landing/contact.service';
import { OmiCallDataRecive, OmiCallService, OmicallData } from 'src/app/services/swicthboard/omicall.service';
import { OmiCallStatus, OmiCallViewType, SwitchBoardCode } from 'src/app/shared/Enums/switchboard';

@Component({
  selector: 'app-omicall-view-dial',
  templateUrl: './omicall-view-dial.component.html',
  styleUrls: ['./omicall-view-dial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush  // Sử dụng OnPush
})
export class OmicallViewDialComponent implements OnInit {

  @Input() dataOmiCall: OmicallData;
  numberPhone: string = null;
  callDuration: moment.Moment = moment().startOf('day');
  seconds: number = 0;
  timer: any;
  infoKhachHang: KhachHangInfoBasic = null;
  isMute: boolean = false;
  isHold: boolean = false;
  isShowDesc: boolean = false;
  descriptionText: string = null;
  userLogin: UserLogin;
  isAutoCreateContact: boolean = false;
  isOnCall: boolean = false;

  constructor(
    private _omiCallService: OmiCallService,
    private cdr: ChangeDetectorRef,
    private _notification: NzNotificationService,
    private _khachHangCustomer: KhachHangService,
    public _userService: UserService,
    public _contactService: ContactService,
    private _cauHinhContactService: CauHinhContactService,
  ) {
  }


  ngOnInit(): void {
    this.GetConfigSetting();
    this._omiCallService.omiDataSubject$.subscribe(data => {
      this.dataOmiCall = data
      if (data.typeView == OmiCallViewType.IN_COMMING && data.status == OmiCallStatus.invite) {
        this.startDuration();
        if (data.phoneShow != null && data.phoneShow != '') {
          this.getInfoCustomer(data.phoneShow);
        }
      }

      if (data.typeView == OmiCallViewType.OUT_COMMING && data.status == OmiCallStatus.connecting) {
        this.startDuration();
        if (data.phoneShow != null && data.phoneShow != '') {
          this.getInfoCustomer(data.phoneShow);
        }
      }

      if (data.status == OmiCallStatus.connected && this.isOnCall == false) {
        this.isOnCall = true;
        this.startDuration();
      }

      if (data.status == OmiCallStatus.ended) {
        this.isOnCall = false;
        this.endDuration();
      }
    })
    this._omiCallService.setPhoneToCallSubject$.subscribe(data => {
      this.numberPhone = data;
    })
    this.getCurrentUser();
  }

  getCurrentUser() {
    this._userService.getCurrentUser().subscribe((res) => {
      if (res) {
        this.userLogin = res;
      }
    });
  }

  startDuration() {
    this.endDuration();
    this.callDuration = moment().startOf('day');
    this.timer = setInterval(() => {
      this.callDuration.add(1, 'second');
      this.cdr.detectChanges();
    }, 1000);
  }

  endDuration() {
    if (this.timer) {
      clearInterval(this.timer);
      this.cdr.detectChanges();
    }
  }

  onClickNumber(number: string) {
    this.numberPhone = '' + (this.numberPhone ?? '') + number;
  }

  onClearAll() {
    this.numberPhone = '';
  }

  onClearOne() {
    if (this.numberPhone != null && this.numberPhone.length > 0 && this.numberPhone != '') {
      this.numberPhone = this.numberPhone.slice(0, -1);
    }
  }

  // onKeydown(data){
  onKeydown(data) {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    if (data.ctrlKey == true && (data.key === 'v' || data.key === 'V')) {
      return;
    }

    if (data.ctrlKey == true && (data.key === 'A' || data.key === 'a')) {
      return;
    }

    if (data.ctrlKey == true && (data.key === 'c' || data.key === 'C')) {
      return;
    }

    if (data.key === 'Enter') {
      this.onCall();
      return;
    }

    if (!isNaN(Number(data.key)) || allowedKeys.includes(data.key)) {
    } else {
      data.preventDefault();
    }
  }

  onCheckRegex() {
    let regex = new RegExp('^[0-9]+$');
    if (!regex.test(this.numberPhone)) {
      return false;
    }
    return true;
  }


  onCall() {
    if (this.numberPhone == null || this.numberPhone.length == 0 || this.numberPhone == '') {
      this._notification.warning('Cảnh báo', 'Vui lòng nhập số điện thoại');
      return;
    } else {
      if (!this.onCheckRegex()) {
        this._notification.warning('Cảnh báo', 'Số điện thoại không hợp lệ');
        return;
      }

      omiSDK.makeCall(this.numberPhone)
      let data: OmiCallDataRecive = {
        CrmUnitId: this.userLogin?.donViId,
        SwitchBoardCode: SwitchBoardCode.OmiCall,
      }
    }
  }

  onClose() {
    this.callDuration = moment().startOf('day');
    this.descriptionText = null;
    this.dataOmiCall = {
      isShowOmiViewDial: true,
      typeView: OmiCallViewType.CALLING,
      status: OmiCallStatus.ended,
    };
    this.infoKhachHang = null;
    this._omiCallService.onCloseAfterCall();
  }

  onCloseByButton() {
    this._omiCallService.setIsShowOmiViewDialSubject();
  }

  onAccept() {
    omiSDK.acceptCall()
  }

  onStop() {
    omiSDK.stopCall()
  }

  onReject() {
    omiSDK.stopCall()
    // clearInterval(this.timer);
  }

  getInfoCustomer(soDienThoai) {
    this._khachHangCustomer.GetInfoKhachHangByOmiCall(soDienThoai).then((res: ResponseDTO<KhachHangInfoBasic>) => {
      if (res.success) {
        this.infoKhachHang = res.data;
      } else {
        this.infoKhachHang = null;
      }
    })
  }
  onChangeMicro(data) {
    this.isMute = data;
    omiSDK.toggleMute(data)
  }
  onChangeHold(data) {
    this.isHold = data;
    omiSDK.toggleHold(data)
  }
  onChangeDesc() {
    this.isShowDesc = !this.isShowDesc;
  }

  onCreateContact() {
    let body = {
      idDonVi: this.userLogin.donViId,
      idUserSale: this.userLogin.id,
      nguonTao: 2,
      type: 3,
      dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
      soDienThoaiKhach: this.dataOmiCall.phoneShow,
      hotline: this.dataOmiCall.callToNumber,
    }
    this._contactService.CreateContactFromHotline(body).then((res: ResponseData) => {
      if (res == null || res == undefined || res.success == false || res.data == false) {
        this._notification.error('Lỗi', 'Tạo contact thất bại');
        return;
      } else {
        this._notification.success('Thành công', 'Tạo contact thành công');
        return;
      }
    });
  }

  GetConfigSetting(): void {
    this._cauHinhContactService.GetCauHinhChoPhepTaoContactKhiCuocGoiDen().then((res) => {
      if (res && res.success && res.data != null) {
        this.isAutoCreateContact = res?.data?.choPhepTuDongTaoContactKhiCoCuocGoiToiDaNhacMay ?? false;
      }
    });
  }
}

export interface KhachHangInfoBasic {
  idChiNhanh?: string
  id?: string
  soDienThoai?: string
  ma?: string
  avatarUrl?: string
  tenKhachHang?: string
  diaChi?: string
  gioiTinh?: Number
  maQuocGia?: string
  maTinh?: string
  maHuyen?: string
  maXa?: string
  hoVaTen?: string
}




