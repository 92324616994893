import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoIncrementService {
  private currentIndex = 0;

  constructor() { }

  getNextIndex(): number {
    return this.currentIndex++;
  }
  resetIndexToZero() {
    // console.log("resst index")
    this.currentIndex = 0;
    // console.log("this.currentIndex", this.currentIndex)
  }
}
