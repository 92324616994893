import * as moment from "moment";
import { environment } from "src/environments/environment";
import { LstKieuGiamGia } from "../Enums/CommonEnums";
import { TimeZones } from "../Enums/TimeZoneEnum";

export function calculateDiff(startDate, endDate = undefined) {
  startDate = new Date(startDate)
  if (!endDate) {
    endDate = new Date();
  }
  var diff = endDate.getTime() - startDate.getTime();
  var days = Math.floor(diff / (60 * 60 * 24 * 1000));
  var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
  var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
  var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
  return { day: days, hour: hours, minute: minutes, second: seconds };
}

export function ConvertTimeSpanToTime(timeSpan) {
  return timeSpan ? (moment().format("YYYY-MM-DD") + " " + timeSpan) : null
}

export function ConvertTimeToTimeSpan(time) {
  return time != null ? moment(time).format("HH:mm:00") : null
}

export function ConvertDateToSave(ngay) {
  return moment(ngay).format();
}


export function addDays(days, date?) {
  if (!date) {
    date = new Date();
  }

  date.setDate(date.getDate() + days);
  return date;
}


export function startDay(date?) {
  if (!date) {
    date = moment();
  }

  //date = date.startOf('day');
  date = moment(date).startOf('days').format()
  return date;
}


export function endDay(date?) {
  if (!date) {
    date = moment();
  }

  //date = date.endOf('day');
  date = moment(date).endOf('days').format()
  return date;
}

export function tinhTien(type, value, tong) {
  // giảm giá theo tiền
  if (type == LstKieuGiamGia[1].id) {
    return value;
  }
  // theo phần trăm
  else {
    return (tong * value) / 100;
  }
}
export function filterSanPham(selectedItems, lstSanPham, id) {
  let selecteds = selectedItems.filter(a => a.id != id);
  return lstSanPham.filter((sanPham) => {
    const sanPhamId = sanPham.id;
    return !selecteds.some((selectedItem) => selectedItem.idSanPham === sanPhamId);
  });
}

export function getUrlImage(idImage: string | null, size: string = 'o'): string | null {
  if (idImage == null || idImage == '')
    return null;
  else {
    return `${environment.SERVICE_API_FILE}Files/GetFile?id=${idImage}&size=${size}`
  }
}

export function formatNumber(num) {
  if (num < 100) {
    return num.toString();
  } else if (num < 1000000) {
    return Math.floor(num / 1000) + ' Nghìn';
  }
  else if (num < 1000000000) {
    return Math.floor(num / 1000000) + ' Triệu';
  } else {
    return Math.floor(num / 1000000000) + 'Tỷ';
  }
}

export function getCurrentTimeByTimeZone(timeZone: TimeZones) {
  // Lấy thời gian hiện tại
  const now = new Date();

  // Chuyển đổi và định dạng theo múi giờ cụ thể
  let options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  const timeString = now.toLocaleString('en-GB', options);

  // Tách và định dạng lại chuỗi để tuân thủ định dạng ISO 8601
  const [date, time] = timeString.split(', ');
  const [day, month, year] = date.split('/');
  const [hours, minutes, seconds] = time.split(':');

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return isoString;
}

function hexToRgb(hex) {
  if (!hex) return { r: 0, g: 0, b: 0 };
  let bigint = parseInt(hex.substring(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
}
export function getColorText(hexColor) {
  if (!hexColor) return 'black';
  let { r, g, b } = hexToRgb(hexColor);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

export function ConvertEmptyStringsToNull(obj) {
  // Kiểm tra nếu obj không phải là một object, trả về nó
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Duyệt qua các property của object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Nếu giá trị là một chuỗi rỗng, chuyển đổi thành null
      if (obj[key] === "") {
        obj[key] = null;
      }
      // Nếu giá trị là một object, đệ quy để xử lý các nested objects
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        ConvertEmptyStringsToNull(obj[key]);
      }
    }
  }

  return obj;
}

export function formatVND(value: number): number {
  if (isNaN(value) || value == null || value == undefined) return 0;

  const formattedValue = Number(value).toFixed(0);

  // Sử dụng parseFloat để chuyển đổi thành số, không nên dùng eval vì lý do an toàn
  return parseFloat(formattedValue);
}

export function formatStringToVND(value: any): any {
  let newNumber = Number(value);
  if (isNaN(newNumber) || newNumber == null || newNumber == undefined) return 0;

  const formattedValue = Number(newNumber).toFixed(0);

  // Sử dụng parseFloat để chuyển đổi thành số, không nên dùng eval vì lý do an toàn
  return parseFloat(formattedValue).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    // currency: 'VND',
    // style: 'currency',
  });
}

export function getNhaMangTheoDauSo(source, phone): string {
  let phone3 = phone.substring(0, 3);
  let phone4 = phone.substring(0, 4);
  for (let i = 0; i < source.length; i++) {
    if (source[i].dauSo.includes(phone3) || source[i].dauSo.includes(phone4)) {
      return source[i].ten;
    }
  }
  return ""
}

export function getColorNhaMangTheoDauSo(source, phone): string {
  let phone3 = phone.substring(0, 3);
  let phone4 = phone.substring(0, 4);
  for (let i = 0; i < source.length; i++) {
    if (source[i].dauSo.includes(phone3) || source[i].dauSo.includes(phone4)) {
      return source[i].color;
    }
  }
  return ""
}


export function stringToNumber(str) {
  if (str == null || str == undefined || str == '') {
    return null;
  }
  console.log(parseInt(str.replace(/,/g, ''), 10));
  return parseInt(str.replace(/,/g, ''), 10);
}

export function numberToString(num) {
  if (num == null || num == undefined || num == '') {
    return null;
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
