import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationComponent } from './pagination.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [CommonModule,NzIconModule,NzButtonModule  ],
  exports: [PaginationComponent],
  declarations: [PaginationComponent]
})
export class PaginationModule { }