import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseData } from '../models/response';
import { Observable, lastValueFrom } from 'rxjs';

export abstract class BaseService {
  table = '';
  _httpClient: HttpClient;
  constructor(httpClient: HttpClient, tableName: string) {
    this.table = tableName;
    this._httpClient = httpClient;
  }

  layTatca(): Promise<ResponseData> {
    const url = `${this.table}/LayTatca`;
    return lastValueFrom(this.get<ResponseData>(url));
  }

  getRoleBySystem(): Promise<ResponseData> {
    const url = `${this.table}/GetRoleBySystem`;
    return lastValueFrom(this.get<ResponseData>(url));
  }

  layTatCaDaiLy(): Promise<ResponseData> {
    const url = `${this.table}/LayTatcaDaiLy`;
    return lastValueFrom(this.get<ResponseData>(url));
  }

  create(body): Promise<ResponseData> {
    const url = `${this.table}/ThemMoi`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  update(body): Promise<ResponseData> {
    const url = `${this.table}/CapNhatThongTin`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  getDetail(id): Promise<ResponseData> {
    const url = `${this.table}/TimTheoId/${id}`;
    return this.get<ResponseData>(url).toPromise();
  }

  delete(id): Promise<ResponseData> {
    const url = `${this.table}/Xoa/${id}`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  XoaLichHen(id): Promise<ResponseData> {
    const url = `${this.table}/XoaLichHen/${id}`;
    return this.post<ResponseData>(url, {}).toPromise();
  }

  timTheoDieuKien(body) {
    const url = `${this.table}/TimTheoDieuKien`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  timTheoMa(ma): Promise<ResponseData> {
    const url = `${this.table}/TimTheoMa?ma=${ma}`;
    return this.get<ResponseData>(url).toPromise();
  }

  duyetNhanVienSale(model): Promise<ResponseData> {
    const url = `${this.table}/NhanVienSaleDuyet`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  danhSachCanDuyet(body): Observable<ResponseData> {
    const url = `${this.table}/DanhSachCanDuyet`;
    return this.post<ResponseData>(url, body);
  }

  PostRequest(url: string, data: any): Promise<ResponseData> {
    return this.post<ResponseData>(url, data).toPromise();
  }

  PostRequestV2(url: string, data: any): Observable<ResponseData> {
    return this.post<ResponseData>(url, data);
  }

  GetRequest(url: string): Promise<ResponseData> {
    return this.get<ResponseData>(url).toPromise();
  }
  GetRequestV2(url: string): Observable<ResponseData> {
    return this.get<ResponseData>(url);
  }

  post<T>(url: string, body: any) {
    return this._httpClient.post<T>(url, body, { withCredentials: true });
  }

  get<T>(url: string) {
    return this._httpClient.get<T>(url, { withCredentials: true });
  }

  getDownload(url: string) {
    return this._httpClient.get(url, {
      withCredentials: true,
      responseType: 'blob',
    });
  }
  postDownload(url: string, body: any) {
    return this._httpClient.post(url, body, {
      withCredentials: true,
      responseType: 'blob',
    });
  }
  // Bản chuẩn
  createV2(body): Promise<ResponseData> {
    const url = `${this.table}/ThemMoi_V2`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  postWithHeaders<T>(url: string, body: any, options: {}) {
    return this._httpClient.post<T>(url, body, {
      withCredentials: true,
      ...options,
    });
  }

  getWithHeaders<T>(url: string, options: {}) {
    return this._httpClient.get<T>(url, {
      withCredentials: true,
      ...options,
    });
  }
}
