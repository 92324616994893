import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  /**
   *
   */
  constructor() {
    // if (environment.moitruong && environment.moitruong.length > 0) {
    //   console.log(environment.moitruong)
    // }

  }
}
