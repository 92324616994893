export const environment = {
  production: true,
  facebook: {
    fbAppVersion: "v17.0",
    fbLinkApi: "https://graph.facebook.com/v17.0/",
    fbTokenWebhook: "", //private
    fbAccessToken: "", //private
    fbAppSecret: "",    //private
    fbAppId: '643313836410065',
    fbMaxAutocreateSource: "1000",
  },
  // //local
  ACCOUNT_API: 'https://stagingapi.sandbox.com.vn/auth/',
  CORE_API: 'https://stagingapi.sandbox.com.vn/core/',
  SERVICE_API_FILE: 'https://stagingapi.sandbox.com.vn/data/',
  SERVICE_API_LOG: 'https://stagingapi.sandbox.com.vn/log/',
  SERVICE_NOTI_API: 'https://stagingapi.sandbox.com.vn/noti/',
  SERVICE_API_WarehouseProduct: 'https://stagingapi.sandbox.com.vn/warehouse/',
  SERVICE_API_Order: 'https://stagingapi.sandbox.com.vn/order/',
  SERVICE_API_CUSTOMER: 'https://stagingapi.sandbox.com.vn/customer/',
  SERVICE_API_BOOKING: 'https://stagingapi.sandbox.com.vn/booking/',
  SERVICE_API_PROMOTION: 'https://stagingapi.sandbox.com.vn/promotion/',
  SERVICE_API_SMS: 'https://stagingapi.sandbox.com.vn/sms/',
  SERVICE_API_BRANCH: 'https://stagingapi.sandbox.com.vn/branch/',
  SERVICE_API_SWITCHBOARD: 'https://stagingapi.sandbox.com.vn/switchboard/',
  SERVICE_API_LANDING: 'https://stagingapi.sandbox.com.vn/landingpage/',
  SERVICE_API_CONTACT: 'https://stagingapi.sandbox.com.vn/contact/',
  SERVICE_API_ORDER_PROCESS: 'https://stagingapi.sandbox.com.vn/orderprocess/',
  SERVICE_NOTI_HUB_API: 'https://stagingapi.sandbox.com.vn/websk/',

  SERVICE_API_WEBSITE: 'https://stagingapi.sandbox.com.vn/website/',
  SERVICE_API_AuthenCustomer: 'https://stagingapi.sandbox.com.vn/authencustomer/',
  SERVICE_API_OrderLogistic: 'https://stagingapi.sandbox.com.vn/orderlogistic/',
  SERVICE_API_REPORT: 'https://stagingapi.sandbox.com.vn/report/',
  SERVICE_API_WEBHOOKFB: 'https://stagingapi.sandbox.com.vn/webhookapi/',
  SERVICE_API_FACEBOOK: 'https://stagingapi.sandbox.com.vn/facebook/',
  SERVICE_API_ESDOCUMENT: 'https://stagingapi.sandbox.com.vn/esdocument/',
  SERVICE_API_WORK: 'https://stagingapi.sandbox.com.vn/work/',
  URL_LOGIN_FACEBOOK: 'https://stagingloginfb.sandbox.com.vn/fb-login',

  RECAPTCHA_SITEKEY: '6Le2O_MoAAAAAMxrbthXTwsIdCq7u8MxVHVJdrkW',
  GOOGLE_MAP_KEY: 'AIzaSyChnaNKhzA7vsVCqE_Mn8ZOaAa60Aq63I4',
  COOKIE_PREFIX: 'sta',
  fireBaseConfig: {
    apiKey: 'AIzaSyD0sOjA_OXgwvmakdJiFQb6ZKaSr3HBQwA',
    authDomain: 'sandboxvn-staging-fullbooking.firebaseapp.com',
    projectId: 'sandboxvn-staging-fullbooking',
    storageBucket: 'sandboxvn-staging-fullbooking.appspot.com',
    messagingSenderId: '258312303357',
    appId: '1:258312303357:web:cfec92c345867733200494',
    measurementId: 'G-7RVDNCJXB6',
  },
  vapidKey:
    'BFvFT_zOD5YdZwFjRwPwk3hukyHTw5VuwI32CG0WMHqwZgf1ItRz9j1z2wmFY5Np-6u5aZ427ksERgsBgGR1gWc',
  domainDefault: '.sandbox.com.vn',
  moitruong: 'Staging',
  domainDebug: '',
};
