
import { UserProfileService } from 'src/app/services/core/userProfile.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLogin } from 'src/app/models/userlogin';
import { UserService } from 'src/app/services/core/user.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseData } from 'src/app/models/response';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { LIST_GIOITINH, REGEX } from 'src/app/constants/config';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { CryptHelperService } from 'src/app/services/cryptohelper.service';
import { ChonTkComponent } from './chon-tk/chon-tk.component';



@Component({
  selector: 'app-form-user-info',
  templateUrl: './form-user-info.component.html',
  styleUrls: ['./form-user-info.component.css']
})
export class FormUserInfoComponent implements OnInit {

  isLoading = false;
  id: any;

  formInput: FormGroup;

  passwordVisible = false;
  listOfRole = [];

  modelUser: any = {};
  modelDonVi: any = {};
  modelUserName: string;
  listGioiTinh = LIST_GIOITINH;
  userLogin: UserLogin;

  isHaveQrCode = false;
  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private _userService: UserService,
    private _userProfileService: UserService,
    private spinner: NgxSpinnerService,
    private _notification: NzNotificationService,
    private authService: AuthService,
    private _cryptHelperService: CryptHelperService,
    private _modalService: NzModalService,
  ) {
    this._userService.getCurrentUser().subscribe(b => {
      this.userLogin = b;
    });
  }

  ngOnInit() {

    this.getUserInfo();

  }

  getUserInfo() {
    this._userProfileService.getThongTinCaNhan().then(rs => {
      if (rs.success) {
        this.modelUser = JSON.parse(this._cryptHelperService.Decrypt_Data(rs.data));
        //console.log(this.modelUser)
        //link qrcode

        if (this.modelUser.ngaySinh) {
          this.modelUser.ngaySinh = new Date(this.modelUser.ngaySinh);
        }
        this.formInput = this.fb.group({
          tenDonVi: [this.modelUser.tenDonVi],
          tenChiNhanh: [this.modelUser.tenChiNhanh],
          userName: [this.modelUser.userName],
          ten: [this.modelUser.ten, [Validators.required]],
          avatarUrl: [this.modelUser.avatarUrl],
          email: [this.modelUser.email, [Validators.email]],
          soDienThoai: [this.modelUser.soDienThoai, [Validators.pattern(REGEX.SO_DIEN_THOAI)],],
          diaChi: [this.modelUser.diaChi],
          gioiTinh: [this.modelUser.gioiTinh],
          ngaySinh: [this.modelUser.ngaySinh],
        });

        this.isLoading = true;
        this.listOfRole = this.modelUser.quyen
      }
    })
  }

  searchUser(event) {
    this.spinner.show();
    this._userProfileService.getDonViByUserName(this.modelUserName).then(rs => {
      this.spinner.hide();
      if (rs.success) {
        // this.modelDonVi = { ...rs.data, id: rs.data.idDonVi, ten: rs.data.tenDonVi }
        this.showPopupChonUser(rs.data);
      } else {
        this.modelDonVi = null;
      }
    })
  }
  showPopupChonUser(datas) {
    let modal = this._modalService.create({
      nzTitle: 'Chọn đơn vị',
      nzContent: ChonTkComponent,
      nzClassName: "modal-nopading",
      nzClosable: true,
      nzFooter: null,
      nzStyle: { top: '50px' },
      nzWidth: 750,
      nzComponentParams: { datas },
      nzMaskClosable: true,
    });
    modal.afterClose.subscribe(res => {
      if (res) {
        this.modelDonVi = { ...res, id: res.idDonVi, ten: res.tenDonVi }
        // this.gridLoadData();
      }
    });

  }

  markFormGroupTouched(formGroup) {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  onSave() {
    this.markFormGroupTouched(this.formInput);
    if (this.formInput.invalid) {
      return;
    }
    let model = { ...this.formInput.getRawValue(), }
    if (this.modelUserName && !this.modelDonVi.idDonVi) {
      this._notification.warning(MESSAGE.WARNING, "Hãy chọn đơn vị!");
      return;
    }
    if (this.modelUserName) {
      model = { ...model, copyByUserName: this.modelDonVi.userName }
    }
    if (this.modelDonVi) {
        model.idDonVi = this.modelDonVi.idDonVi;
    }

    this.spinner.show();
    this._userProfileService.CapNhatThongTinCaNhan(model).then((res: ResponseData) => {
      this.spinner.hide();
      if (res.success) {
        // if(this.IsCoQuyen()){
        //   //Get lại userinfo
        //   this._userProfileService.getUserInfo().then(rs =>{
        //     if(rs.success){
        //       this.authService.saveUser(rs.data);
        //       this._notification.success(MESSAGE.SUCCESS, "Cập nhật thành công");
        //     }
        //   })
        // }else{
        //   this._notification.success(MESSAGE.SUCCESS, "Cập nhật thành công");
        // }
        this._userProfileService.ClearCurrentUser();
        this._notification.success(MESSAGE.SUCCESS, "Cập nhật thành công");
        this.modal.close(false);
        this._userProfileService.reloadDataChiNhanhHeader();
      } else {
        this._notification.warning(MESSAGE.WARNING, res.message);
      }
    })

  }


  IsCoQuyen() {
    if (this.userLogin && this.userLogin.isSuperUser == true || this.userLogin.roleCodes.includes('HT_CSKH')) return true;

    return false;
  }
  onThoat() {
    this.modal.close(false);
  }

}
