import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { IBreadcrumb } from "../../shared/interfaces/breadcrumb.type";
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { UserService } from 'src/app/services/core/user.service';
import { PageService } from 'src/app/services/sanboxsarea/page.service';
import { OmiCallService, OmicallData } from 'src/app/services/swicthboard/omicall.service';
import { OmiCallStatus, OmiCallViewType } from 'src/app/shared/Enums/switchboard';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { MyHubService } from 'src/app/services/swicthboard/myhub.service';
import { TongDaiUserService } from 'src/app/services/swicthboard/tong-dai-user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
})

export class CommonLayoutComponent {

  breadcrumbs$: Observable<IBreadcrumb[]>;
  contentHeaderDisplay: string;
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  selectedHeaderColor: string;
  isShowOmiViewDial: boolean = false;
  isShowMyHub: boolean = false;
  isActiveOmiCall: boolean = false;
  dataOmiCall: OmicallData = {
    isShowOmiViewDial: false,
    typeView: OmiCallViewType.DIAL,
    callFromNumber: null,
    callToNumber: null,
  };
  isDraged: boolean = false;
  callDuration: moment.Moment = moment().startOf('day');
  timer: any;

  topPosition = 50 + '%';
  leftPosition = 50 + '%';



  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeConstantService,
    private cdr: ChangeDetectorRef,
    private _omiCallService: OmiCallService,
    private _myhubService: MyHubService,
    private _tongDaiUserService: TongDaiUserService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['headerDisplay']) {
            return child.snapshot.data['headerDisplay'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      this.contentHeaderDisplay = data;
    });
    this._tongDaiUserService.GetInfoUserSwitchboard().subscribe((rs) => {

    })
    // this._omiCallService.isShowOmiViewDialSubject$.subscribe(res => {
    //   this.isShowOmiViewDial = res;
    //   this.cdr.detectChanges();
    // });
  }

  // onDragEnded(event: CdkDragEnd) {
  //   this.isDraged = true;
  //   this.topPosition = `${event.source.element.nativeElement.offsetTop}px`;
  //   this.leftPosition = `${event.source.element.nativeElement.offsetLeft}px`;
  // }

  ngOnInit() {
    this._omiCallService.omiDataSubject$.subscribe((res: OmicallData) => {
      if (res.typeView == OmiCallViewType.IN_COMMING && res.status == OmiCallStatus.invite) {
        this.startDuration();
      }
      this.isShowOmiViewDial = res.isShowOmiViewDial ?? false;
      this.dataOmiCall = res;
      if (this.cdr) {
        this.cdr.detectChanges();
      }

      if (res.status == OmiCallStatus.ended) {
        this.endDuration();
      }

    });

    this._omiCallService.isShowOmiViewDialSubject$.subscribe(res => {
      this.isShowOmiViewDial = res;
      if (this.cdr) {
        this.cdr.detectChanges();
      }
    });


    this._omiCallService.isActiveOmiViewDialSubject$.subscribe(res => {
      this.isActiveOmiCall = res;
      if (this.cdr) {
        this.cdr.detectChanges();
      }
    });


    this._myhubService.isShowListHotlineSubject$.subscribe(res => {
      this.isShowMyHub = res;
      if (this.cdr) {
        this.cdr.detectChanges();
      }
    });


    this.breadcrumbs$ = this.router.events.pipe(
      startWith(new NavigationEnd(0, '/', '/')),
      filter(event => event instanceof NavigationEnd), distinctUntilChanged(),
      map(data => this.buildBreadCrumb(this.activatedRoute.root))
    );
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    // var roles = this.userService.getUserLogin().roleCodes;
    // if (roles) {
    //     this.pageService.GetHomePage().subscribe(res => {

    //         if (res.success && res.data.url) {
    //             this.router.navigateByUrl(res.data.url)
    //         }
    //     })
    // }
    var parentElement = document.getElementById("screen-main");
    var parentWidth = parentElement.offsetWidth;
    if (parentWidth < 1000) {
      this.themeService.toggleFold(true);
      this.themeService.toggleExpand(false);
    }
    else {
      this.themeService.toggleFold(false);
      this.themeService.toggleExpand(true);
    }

  }

  startDuration() {
    this.callDuration = moment().startOf('day');
    this.timer = setInterval(() => {
      this.callDuration.add(1, 'second');
      this.cdr.detectChanges();
    }, 1000);
  }

  endDuration() {
    this.callDuration = moment().startOf('day');
    clearInterval(this.timer);
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var parentElement = document.getElementById("screen-main");
    var parentWidth = parentElement.offsetWidth;
    if (parentWidth < 1000) {
      this.themeService.toggleFold(true);
      this.themeService.toggleExpand(false);
    }
    else {
      this.themeService.toggleFold(false);
      this.themeService.toggleExpand(true);
    }
  }

  private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    let label = '', path = '/', display = null;

    if (route.routeConfig) {
      if (route.routeConfig.data) {
        label = route.routeConfig.data['title'];
        path += route.routeConfig.path;
      }
    } else {
      label = 'Dashboard';
      path += 'dashboard';
    }

    const nextUrl = path && path !== '/dashboard' ? `${url}${path}` : url;
    const breadcrumb = <IBreadcrumb>{
      label: label, url: nextUrl
    };

    const newBreadcrumbs = label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  onChangeShowOmiViewDial() {
    this._omiCallService.setIsShowOmiViewDialSubject();
  }
}
