import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class DonviService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.CORE_API}api/DonVi`);
  }

  GetDonViById(): Promise<ResponseData> {
    let url = `${this.table}/GetDonViById`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  GetAllInfoBasic(): Promise<ResponseData> {
    let url = `${this.table}/GetAllInfoBasic`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  getUnitByRole(body) {
    const url = `${this.table}/GetUnitByRole`;
    return this.post<ResponseData>(url, body).toPromise();
  }

  genCode(payload: any): Promise<ResponseData> {
    const url = `${this.table}/genCode`;
    return this.httpClient.post<ResponseData>(url, payload).toPromise();
  }

  SearchByKeyword(body) {
    const url = `${environment.CORE_API}api/QuanTriArea/DonVi/SearchByKeyword`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
  
  //Lấy endpoint đặt lịch của đơn vị
  LayDatLichEndPointDonVi() {
    const url = `${this.table}/LayDatLichEndPointDonVi`;
    return this.get<ResponseData>(url);
  }

}
