<ng-container *ngIf="nzShowTime">
  <nz-date-picker
  [nzShowTime]="{ nzFormat: 'HH:mm' }"
  [nzAllowClear]="nzAllowClear"
  nzFormat="dd/MM/yyyy HH:mm"
  [(ngModel)]="inputValue"
  (ngModelChange)="updateModel($event)"
  (nzOnOk)="updateModel($event)"
  (keyup.enter)="onKeyupEnter($event)"
  (keydown.Tab)="onKeyupEnter($event)"
  [nzPlaceHolder]="placeHolder"
  [nzDisabled]="nzDisabled"
  [style]="styleCss"
  [nzSize]="'small'"
  >
  </nz-date-picker>
</ng-container>

<ng-container *ngIf="!nzShowTime">
  <nz-date-picker [nzAllowClear]="nzAllowClear" nzFormat="dd/MM/yyyy"
  [(ngModel)]="inputValue"
  (ngModelChange)="updateModel($event)"
  (keyup.enter)="onKeyupEnter($event)"
  (keydown.Tab)="onKeyupEnter($event)"
  (nzOnOk)="updateModel($event)"
  [nzPlaceHolder]="placeHolder"
  [nzDisabled]="nzDisabled"
  [style]="styleCss"
  [nzSize]="'small'"
  >
  </nz-date-picker>
</ng-container>
