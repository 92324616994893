import { Injectable } from "@angular/core";
import { HttpClient } from "@microsoft/signalr";
import { HotlineService } from "./hotline.service";
import { ResponseData } from "src/app/models/response";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class MyHubService {
  private static isTookHotline: boolean = false;
  private static isShowListHotline: boolean = false;
  private static listHotline: Array<IHotlineMyHub> = [];
  private isInitMyHub = new BehaviorSubject<boolean>(null);
  constructor(
    private _hotlineService: HotlineService,
  ) {

  }

  private isShowListHotlineSubject = new BehaviorSubject<boolean>(false);
  isInitMyHub$ = this.isInitMyHub.asObservable();

  isShowListHotlineSubject$ = this.isShowListHotlineSubject.asObservable();


  private listHotlineSubject = new BehaviorSubject<any[]>([]);

  listHotlineSubject$ = this.listHotlineSubject.asObservable();

  private dataCallSubject = new BehaviorSubject<IDataClickToCallMyHub>(null);

  dataCallSubject$ = this.dataCallSubject.asObservable();

  setShowListHotline() {
    MyHubService.isShowListHotline = !MyHubService.isShowListHotline;
    this.isShowListHotlineSubject.next(MyHubService.isShowListHotline);
  }

  sendHotlineSubject() {
    this.listHotlineSubject.next(MyHubService.listHotline);
  }

  sendDataCallSubject(data: IDataClickToCallMyHub) {
    this.dataCallSubject.next(data);
  }


  async setUpInit() {
    if (!MyHubService.isTookHotline) {
      await this.getHotline();
      this.sendHotlineSubject();
    }
  }

  private async getHotline() {
    await this._hotlineService.GetHotline().then((rs: ResponseData) => {
      if (rs.success) {
        MyHubService.isTookHotline = true;
        MyHubService.listHotline = rs.data
        if (rs.data && rs.data.length > 0) {
          this.isInitMyHub.next(true);
        } else {
          this.isInitMyHub.next(false);
        }
      } else {
        this.isInitMyHub.next(false);
      }
    })
  }

  async reloadHotline() {
    MyHubService.isTookHotline = false;
    await this.getHotline();
    this.sendHotlineSubject();
  }

  clickToCall(data: IDataClickToCallMyHub): Promise<IResultMyhub> {
    return this._hotlineService.ClickToCall(data).then((rs: ResponseData) => {
      let data: IResultMyhub = {
        IsSuccess: rs.success,
        Message: rs.message
      };
      return data;
    })
      .catch((err) => {
        let data: IResultMyhub = {
          IsSuccess: false,
          Message: err
        };
        return data;
      })
  }

  resetMyHub() {
    MyHubService.isShowListHotline = false;
    MyHubService.listHotline = [];
  }
}

export interface IHotlineMyHub {
  SoDienThoai?: string,
  HotlineId?: string,
  Network?: string
}

export interface IDataClickToCallMyHub {
  HotlineId?: string,
  To: string,
  IdContact?: string,
  IdKhachHang?: string,
  IdDonHang?: string,
  NgayGoiDien?: string,
  NgayTaoContact?: string,
  IdTacNghiepCan?: string,
}

export interface IResultMyhub {
  IsSuccess?: boolean,
  Message?: any,
}

