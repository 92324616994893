import { PageService } from 'src/app/services/sanboxsarea/page.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { catchError, concat, map, Observable, of } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/core/user.service';
import { UserLogin } from '../models/userlogin';
import { MenuService } from '../services/core/menu.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private _pageService: MenuService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      let url: string = state.url;
      return new Observable<boolean>(obs => {
        this.userService.getCurrentUser().subscribe((user : UserLogin) =>{
          if(user){
            if(url){
              url = url.split("?")[0]
            }
            if(url == "/"){
              obs.next(true);
              obs.complete();
            }else{
              this._pageService.CheckUserAccess({url: url }).subscribe(rs =>{
                if(rs.data){
                   obs.next(true);
                   obs.complete();
                }else{
                  this.authService.redirectUrl = url;
                  obs.next(false);
                  this.router.navigate(['/']);
                }
              },(err) =>{
                obs.next(false);
                this.router.navigate(['/']);
              })

            }

           

          }else{
            this.userService.ClearCurrentUser();
            this.authService.redirectUrl = url;
            obs.next(false);
            this.router.navigate(['login'],{ queryParams: { goto: url } });

          }

        });
      });

  }

}
