import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { ResponseData } from '../../models/response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MenuService extends BaseService{

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.CORE_API}api/Menu`)
  }

  
  layTatcaMenu(): Promise<ResponseData> {
    const url = `${this.table}/LayTatcaMenu`;
    return this.GetRequest(url);
  }
    
  CheckUserAccess(model): Observable<ResponseData> {
    const url = `${this.table}/CheckUserAccess`;
    return this.PostRequestV2(url,model);
  }

}
