import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { MESSAGE } from 'src/app/constants/message';
import { IDataClickToCallMyHub, IResultMyhub, MyHubService } from 'src/app/services/swicthboard/myhub.service';

@Component({
  selector: 'app-myhub-view-hotline',
  templateUrl: './myhub-view-hotline.component.html',
  styleUrls: ['./myhub-view-hotline.component.scss']
})
export class MyhubViewHotlineComponent implements OnInit {
  @Input() IdKhachHang;
  @Input() SoDienThoai;
  @Input() Phone;
  @Input() IdDonHang = null;
  @Input() IdContact = null;
  @Input() NgayTaoContact = null;
  @Input() NgayGoiDien = null;

  dataCall: IDataClickToCallMyHub = null;
  tongDai: any = {
    tongDaiText: "My hub",
  };
  idNhanVien: any;
  idKhachHang: any;
  isLoad: boolean = true;
  data: Array<any> = []
  constructor(
    private notification: NzNotificationService,
    private _myhubService: MyHubService,
    private _spinner: NgxSpinnerService
  ) {
  }
  async ngOnInit() {
    this._myhubService.listHotlineSubject$.subscribe((rs: any) => {
      this._spinner.hide();
      this.data = rs;
    })
    this._myhubService.dataCallSubject$.subscribe((rs: any) => {
      this.dataCall = rs;
    })
  }

  onCloseListHotline() {
    this.dataCall = null;
    this._myhubService.setShowListHotline();
  }

  reloadHotline() {
    this._spinner.show();
    this._myhubService.reloadHotline();
  }

  closeModal() {
    this.onCloseListHotline();
  }

  clickToCall(hotline) {
    if (hotline == null) {
      this.notification.error(MESSAGE.ERROR, 'Vui lòng chọn số điện thoại!');
      return;
    }
    if (this.dataCall == null) {
      this.notification.error(MESSAGE.ERROR, 'Chưa tiếp nhận được thông tin cuộc gọi!');
      return;
    }
    if (this.dataCall.To == null) {
      this.notification.error(MESSAGE.ERROR, 'Chưa lấy được số điện thoại khách hàng!');
      return;
    }
    this.dataCall.HotlineId = hotline;
    this._spinner.show();
    this._myhubService.clickToCall(this.dataCall).then((rs: IResultMyhub) => {
      this._spinner.hide();
      if (rs.IsSuccess) {
        this.notification.success(MESSAGE.SUCCESS, rs.Message);
        return;
      } else {
        this.notification.error(MESSAGE.ERROR, rs.Message);
        return;
      }
    })
  }
}
