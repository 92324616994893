<div class="row" style="padding:30px">
    <nz-table style="width:100%" #basicTable [nzData]="datas">
        <thead>
            <tr>
                <th>UserName</th>
                <th>Tên đơn vị</th>
                <th>Tên chi nhánh</th>
                <th>Tên miền</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td>{{item?.userName}}</td>
                <td>{{item?.tenDonVi}}</td>
                <td>{{item?.tenChiNhanh}}</td>
                <td>{{item?.domain}}</td>
                <td>
                    <button nz-button [nzSize]="'small'" nzType="default" nz-tooltip nzTooltipTitle="Chọn"
                        (click)="onChon(item)">
                        <i nz-icon nzType="select" theme="outline"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </nz-table>
</div>