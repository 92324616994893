import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseData } from 'src/app/models/response';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})

export class HotlineService extends BaseService {
  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient, `${environment.SERVICE_API_SWITCHBOARD}api/MyHubHotline`)
  }

  GetHotline() {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/MyHubHotline/GetHotline`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  ClickToCall(body) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/MyHub/ClickToCall`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetHotlineByIdUserPartner(body) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/MyHubHotline/GetHotlineByIdUserPartner`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetHistoryActiveHotline(body) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/SanboxArea/Hotline/GetHistoryActiveHotline`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  ExtendTimeHotline(body) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/SanboxArea/Hotline/ExtendTimeHotline`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetHistoryByCustomer(body) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/MyHubCallHistory/GetHistoryByCustomer`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  TimTheoDieuKien(body) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/MyHubCallHistory/TimTheoDieuKien`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
}


