export const STORAGE_KEY = {
  ACCESS_TOKEN: 'access_token',
  ACCESS_TOKEN_REFRESH: 'refresh_token',
  ROLE: 'role',
  USER_INFO: 'user_info',
  VT_TRINH_VB_DEN: 'vt_trinh_vb_den',
  VT_TRINH_VB_DI: 'vt_trinh_vb_di',
};

export const STATUS_CODE = {
  SUCCESS: 200,
  ERROR: 201,
  UNAUTHORIZED: 401,
};

export const API_STATUS = {
  SUCCESS: 1,
  ERROR: 0,
};

export const SYSTYPE_CONFIRM = {
  BE: 'Trong hệ thống',
  FE: 'Ngoài hệ thống',
};

export const DOCUMENT_TYPE_ID = {
  VAN_BAN_DEN: '1',
  VAN_BAN_DI: '2',
  VAN_BAN_DUTHAO: '3',
  TO_TRINH: '4',
  VAN_BAN_NOIBO: '5',
};

export const TYPE_MODAL = {
  CONG_BO: '1',
  XIN_Y_KIEN: '2',
};

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
export const PDF_EXTENSION = '.pdf';
export const DOCX_EXTENSION = '.docx';
export const DOC_EXTENSION = '.doc';
export const XLSX_EXTENSION = '.xlsx';
export const XLS_EXTENSION = '.xls';
export const MP4_EXTENSION = '.mp4';
export const PAGE_SIZE_DEFAULT = 10;
export const MAXIMUM_FILE_UPLOAD = 20480000;
export const PAGE_OPTIONS: number[] = [5, 10, 25, 50];

export const TABLE_LOG = {
  VanBanDen: 'DocumentIn',
  VanBanDi: 'DocumentOut',
};

export const ERROR_CODE = {
  ERROR_BOOKNUMBER: 'Error_BookNumber',
  ERROR_DOCUMENTCODE: 'Error_DocumentCode',
  ERROR_LANHDAO: 'ERROR_100',
};

export const DONVI_NGOAINGANH = {
  TRONG_BTC: '1',
  NGOAI_BTC: '2',
};

export const FORM_ACTION = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

export const LIST_DONVI_KIEULOAI: any[] = [
  {
    id: 1,
    ten: 'Chủ đầu tư',
  },
  {
    id: 2,
    ten: 'Đại lý',
  },
];

export const LIST_GIOITINH: any[] = [
  {
    id: 'NAM',
    ten: 'Nam',
  },
  {
    id: 'NU',
    ten: 'Nữ',
  },
  {
    id: 'KHAC',
    ten: 'Khác',
  },
];

export const KIEU_LOAI_NHAN_THONGBAO = {
  TAT_CA: 0,
  DON_VI: 1,
  CA_NHAN: 2,
};

export const KIEU_LOAI_THONGBAO = {
  TIN_TUC: '1',
  THONG_BAO: '2',
  TINTUC_CUDAN: '3', // chủ hộ hoặc role thành viên chủ hộ
  TINTUC_ANNINH: '4', // Scanner
  KIEN_THUC_SALE: '5', // Scanner
};

export const KIEU_LOAI_MXH_FEED = {
  CONG_DONG: '1',
  KIEN_THUC_SALE: '2',
};

export const SORT_DIRECTION = {
  ASCE: 1,
  DESC: 2,
};

export const LIST_LEVELSALE: any[] = [
  {
    id: 1,
    ten: 'Sale Cộng Tác Viên',
  },
  {
    id: 2,
    ten: 'Sale Chính Thức EcoCentralPark',
  },
  {
    id: 3,
    ten: 'Sale Triệu Phú Ecopark',
  },
  {
    id: 4,
    ten: 'Sale Tỷ Phú Ecopark',
  },
];

export const VerificationCode_Type = {
  NhapGiaBds: 1,
  PhanQuyenBds: 2,
  DoiMatKhau: 3,
  LayLaiMatKhau: 4,
};
export const QUANHE_CHUHO: any[] = [
  {
    id: 1,
    ten: 'Người thân',
  },
  {
    id: 2,
    ten: 'Thành viên',
  },
  {
    id: 3,
    ten: 'Khác',
  },
];
export const role_code = {
  SALE: 'SALE',
  ADMIN_CHUDAUTU: 'ADMIN_CHUDAUTU',
  ADMIN_QUANTRICHUNG: 'ADMIN_QUANTRICHUNG',
  LE_TAN: 'LE_TAN',
};

export const XEBUS_DIEM_LEN_XUUONG = [
  {
    value: 1,
    text: 'Điểm Lên xuống',
  },
  {
    value: 2,
    text: 'Điểm Lên',
  },
  {
    value: 3,
    text: 'Điểm xuống',
  },
];

export const DEVICE_TYPE = {
  QR_DEVICE_2_DAU_DOC: 1, //Bộ thiết bị nhận dạng mã QR (2 đầu đọc) (Lắp đặt tại công viên)
  QR_DEVICE_1_DAU_DOC: 2, //Bộ thiết bị nhận dạng mã QR (1 đầu đọc) (Lắp đặt trên xe Bus)
};

export const LOAI_CHUC_DANH = {
  SALE: 1,
  ORTHER: 2,
};

export const REGEX = {
  SO_DIEN_THOAI: '^(84|0[3|5|7|8|9])+([0-9]{8}$)',
  EMAIL: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}$',
  DOMAIN: '^[A-Za-z0-9.-]+$',
  PASSWORD: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
};

export const USER_TYPE = {
  SYSTEM: 1,
  ORGANIZATION: 2,
  INTRODUCER: 3,
};

export const MONTH_USE = {
  ONE_MONTH: 1,
  THREE_MONTH: 3,
  SIX_MONTH: 6,
  TWELVE_MONTH: 12,
  ORTHER: -1,
};

export const MONTH_USE_TEXT = {
  ONE_MONTH: '1 tháng',
  THREE_MONTH: '3 tháng',
  SIX_MONTH: '6 tháng',
  TWELVE_MONTH: '1 năm',
  ORTHER: 'Khác',
};

export const BEFORE_BOOKING_DAYS = [3];

export enum ListMaPage_Filter {
  CEO_BAO_CAO_SO_LUONG_KHACH_HANG = 'Filter_CEO_BAO_CAO_SO_LUONG_KHACH_HANG',
  CEO_BAO_CAO_CHAM_CONG_TONG_QUAT = 'Filter_CEO_BAO_CAO_CHAM_CONG_TONG_QUAT',
  CEO_BAO_CAO_CHAM_CONG_CHI_TIET = 'Filter_CEO_BAO_CAO_CHAM_CONG_CHI_TIET',
  BAO_CAO_SO_LUONG_KHACH_HANG = 'Filter_BAO_CAO_SO_LUONG_KHACH_HANG',
  BAO_CAO_CHAM_CONG_TONG_QUAT = 'Filter_BAO_CAO_CHAM_CONG_TONG_QUAT',
  BAO_CAO_CHAM_CONG_CHI_TIET = 'Filter_BAO_CAO_CHAM_CONG_CHI_TIET',
  QUAN_LY_BSSID_WIFI = 'Filter_QUAN_LY_BSSID_WIFI',
  QUAN_LY_IMEI = 'Filter_QUAN_LY_IMEI',
  QUAN_LY_DANH_SACH_CONTACT = 'Filter_QUAN_LY_DANH_SACH_CONTACT',
  MARKETING_DASHBOARD = 'Filter_MARKETING_DASHBOARD',
  CAUHINH_GIAMGIA_DONHANG = 'Filter_CAUHINH_GIAMGIA_DONHANG',
  QUANLY_LEAD = 'Filter_QUANLY_LEAD',
  LICH_SU_CUOC_GOI = 'Filter_LICH_SU_CUOC_GOI',
  CauHinh_MatHang_MacDinh = 'Filter_CauHinh_MatHang_MacDinh',
  DonHangSale = 'Filter_DonHangSale',
  KhoSo = 'Filter_KhoSo',
  Thu_Kho_Tac_Nghiep = 'Filter_Thu_Kho_Tac_Nghiep',
  Lich_Su_Thay_Doi_Trang_Thai_Giao_Hang = 'Filter_Lich_Su_Thay_Doi_Trang_Thai_Giao_Hang',
  BAO_CAO_CHAM_CONG = 'Filter_BAO_CAO_CHAM_CONG',
  BAO_CAO_CHAM_CONG_USER = 'Filter_BAO_CAO_CHAM_CONG_USER',
  REPORT_BANG_XEP_HANG = 'Filter_REPORT_BANG_XEP_HANG',
  REPORT_BANG_XEP_HANG_MKT = 'Filter_REPORT_BANG_XEP_HANG_MKT',

  FACEBOOK_QUAN_LY_DANH_SACH_SERVER = 'Filter_FACEBOOK_QUAN_LY_DANH_SACH_SERVER',
  FACEBOOK_QUAN_LY_DANH_SACH_FANPAGE = 'Filter_FACEBOOK_QUAN_LY_DANH_SACH_FANPAGE',
  FACEBOOK_QUAN_LY_DANH_SACH_POST = 'Filter_FACEBOOK_QUAN_LY_DANH_SACH_POST',
  FACEBOOK_QUAN_LY_DANH_SACH_COMMENT = 'Filter_FACEBOOK_QUAN_LY_DANH_SACH_COMMENT',
  FACEBOOK_QUAN_LY_DANH_SACH_WEBHOOK = 'Filter_FACEBOOK_QUAN_LY_DANH_SACH_WEBHOOK',

  REPORT_DOANH_SO_TONG_QUAT = 'Filter_REPORT_DOANH_SO_TONG_QUAT',
  REPORT_PHONG_THAN_BANG = 'Filter_REPORT_PHONG_THAN_BANG',
  REPORT_DOANH_SO_THEO_GIAO_VAN = 'Filter_REPORT_DOANH_SO_THEO_GIAO_VAN',
  REPORT_CONG_VIEC_MKT = 'Filter_REPORT_CONG_VIEC_MKT',
  //report-cong-viec-sale
  REPORT_CONG_VIEC_SALE = 'Filter_REPORT_CONG_VIEC_SALE',
  //report-toi-uu-sale
  REPORT_TOI_UU_SALE = 'Filter_REPORT_TOI_UU_SALE',
  //Báo cáo xếp hạng sale
  REPORT_BANG_XEP_HANG_SALE = 'Filter_REPORT_BANG_XEP_HANG_SALE',
  //report-theo-khach-hang
  REPORT_THEO_KHACH_HANG = 'Filter_REPORT_THEO_KHACH_HANG',
  REPORT_CHI_NHANH = 'Filter_REPORT_CHI_NHANH',
  REPORT_DAI_LY = 'Filter_REPORT_DAI_LY',
  REPORT_CUSTOMERS = 'Filter_REPORT_CUSTOMERS',
  REPORT_PRODUCT = 'Filter_REPORT_PRODUCT',
  REPORT_CASHIERS = 'Filter_REPORT_CASHIERS',
  REPORT_VISITOR = 'Filter_REPORT_VISITOR',
  REPORT_CHIASO = 'Filter_REPORT_CHIASO',
  REPORT_CHUYENDOI_LICHHEN_DOANHTHU = 'Filter_REPORT_CHUYENDOI_LICHHEN_DOANHTHU',
  REPORT_DOANHSO_THEO_CHINHANH = 'Filter_REPORT_DOANHSO_THEO_CHINHANH',
  REPORT_SOLIEU_KHACHHANG_QUAYLAI = 'Filter_REPORT_SOLIEU_KHACHHANG_QUAYLAI',
  REPORT_NHAN_KHACHHANG = 'Filter_REPORT_NHAN_KHACHHANG',
  REPORT_DANH_SACH_CONTACT_THEO_NHAN = 'Filter_REPORT_DANH_SACH_CONTACT_THEO_NHAN',
  REPORT_TYLE_CHOTDON_SANPHAM = 'Filter_REPORT_TYLE_CHOTDON_SANPHAM',
  KHACH_HANG_360 = 'Filter_KHACH_HANG_360',
}

export enum ListMaPage_GridColumns {
  CEO_BAO_CAO_SO_LUONG_KHACH_HANG = 'GridColumns_CEO_BAO_CAO_SO_LUONG_KHACH_HANG',
  CEO_BAO_CAO_CHAM_CONG_TONG_QUAT = 'GridColumns_CEO_BAO_CAO_CHAM_CONG_TONG_QUAT',
  CEO_BAO_CAO_CHAM_CONG_CHI_TIET = 'GridColumns_CEO_BAO_CAO_CHAM_CONG_CHI_TIET',
  BAO_CAO_SO_LUONG_KHACH_HANG = 'GridColumns_BAO_CAO_SO_LUONG_KHACH_HANG',
  BAO_CAO_CHAM_CONG_TONG_QUAT = 'GridColumns_BAO_CAO_CHAM_CONG_TONG_QUAT',
  BAO_CAO_CHAM_CONG_CHI_TIET = 'GridColumns_BAO_CAO_CHAM_CONG_CHI_TIET',
  QUAN_LY_BSSID_WIFI = 'GridColumns_QUAN_LY_BSSID_WIFI',
  QUAN_LY_IMEI = 'GridColumns_QUAN_LY_IMEI',
  QUAN_LY_DANH_SACH_CONTACT = 'GridColumns_QUAN_LY_DANH_SACH_CONTACT',
  MARKETING_DASHBOARD = 'GridColumns_MARKETING_DASHBOARD',
  CAUHINH_GIAMGIA_DONHANG = 'GridColumns_CAUHINH_GIAMGIA_DONHANG',
  QUANLY_LEAD = 'GridColumns_QUANLY_LEAD',
  LICH_SU_CUOC_GOI = 'GridColumns_LICH_SU_CUOC_GOI',
  CauHinh_MatHang_MacDinh = 'GridColumns_CauHinh_MatHang_MacDinh',
  DonHangSale = 'GridColumns_DonHangSale',
  KhoSo = 'GridColumns_KhoSo',
  Thu_Kho_Tac_Nghiep = 'GridColumns_Thu_Kho_Tac_Nghiep',
  Lich_Su_Thay_Doi_Trang_Thai_Giao_Hang = 'GridColumns_Lich_Su_Thay_Doi_Trang_Thai_Giao_Hang',
  BAO_CAO_CHAM_CONG = 'GridColumns_BAO_CAO_CHAM_CONG',
  REPORT_BANG_XEP_HANG = 'GridColumns_REPORT_BANG_XEP_HANG',

  FACEBOOK_QUAN_LY_DANH_SACH_SERVER = 'GridColumns_FACEBOOK_QUAN_LY_DANH_SACH_SERVER',
  FACEBOOK_QUAN_LY_DANH_SACH_FANPAGE = 'GridColumns_FACEBOOK_QUAN_LY_DANH_SACH_FANPAGE',
  FACEBOOK_QUAN_LY_DANH_SACH_POST = 'GridColumns_FACEBOOK_QUAN_LY_DANH_SACH_POST',
  FACEBOOK_QUAN_LY_DANH_SACH_COMMENT = 'GridColumns_FACEBOOK_QUAN_LY_DANH_SACH_COMMENT',
  FACEBOOK_QUAN_LY_DANH_SACH_WEBHOOK = 'GridColumns_FACEBOOK_QUAN_LY_DANH_SACH_WEBHOOK',

  REPORT_DOANH_SO_TONG_QUAT = 'GridColumns_REPORT_DOANH_SO_TONG_QUAT',
  REPORT_PHONG_THAN_BANG = 'GridColumns_REPORT_PHONG_THAN_BANG',
  REPORT_DOANH_SO_THEO_GIAO_VAN = 'GridColumns_REPORT_DOANH_SO_THEO_GIAO_VAN',
  //report-cong-viec-mkt
  REPORT_CONG_VIEC_MKT = 'GridColumns_REPORT_CONG_VIEC_MKT',
  //report-bang-xep-hang-mkt
  REPORT_BANG_XEP_HANG_MKT = 'GridColumns_REPORT_BANG_XEP_HANG_MKT',
  //report-cong-viec-sale
  REPORT_CONG_VIEC_SALE = 'GridColumns_REPORT_CONG_VIEC_SALE',
  //report-toi-uu-sale
  REPORT_TOI_UU_SALE = 'GridColumns_REPORT_TOI_UU_SALE',
  //Báo cáo xếp hạng sale
  REPORT_BANG_XEP_HANG_SALE = 'GridColumns_REPORT_BANG_XEP_HANG_SALE',
  //report-theo-khach-hang
  REPORT_THEO_KHACH_HANG = 'GridColumns_REPORT_THEO_KHACH_HANG',
  REPORT_CHI_NHANH = 'GridColumns_REPORT_CHI_NHANH',
  REPORT_DAI_LY = 'GridColumns_REPORT_DAI_LY',
  REPORT_CUSTOMERS = 'GridColumns_REPORT_CUSTOMERS',
  REPORT_PRODUCT = 'GridColumns_REPORT_PRODUCT',
  REPORT_CASHIERS = 'GridColumns_REPORT_CASHIERS',
  REPORT_VISITOR = 'GridColumns_REPORT_VISITOR',
  REPORT_CHIASO = 'GridColumns_REPORT_CHIASO',
  REPORT_CHUYENDOI_LICHHEN_DOANHTHU = 'GridColumns_REPORT_CHUYENDOI_LICHHEN_DOANHTHU',
  REPORT_DOANHSO_THEO_CHINHANH = 'GridColumns_REPORT_DOANHSO_THEO_CHINHANH',
  REPORT_SOLIEU_KHACHHANG_QUAYLAI = 'GridColumns_REPORT_SOLIEU_KHACHHANG_QUAYLAI',
  REPORT_NHAN_KHACHHANG = 'GridColumns_REPORT_NHAN_KHACHHANG',
  REPORT_DANH_SACH_CONTACT_THEO_NHAN = 'GridColumns_REPORT_DANH_SACH_CONTACT_THEO_NHAN',
  REPORT_TYLE_CHOTDON_SANPHAM = 'GridColumns_REPORT_TYLE_CHOTDON_SANPHAM',
  KHACH_HANG_360 = 'GridColumns_KHACH_HANG_360',
}

export interface VirtualDataInterface {
  index: number;
  id: string;
}

export const COOKIE_PAGE_SIZE_REPORT = "COOKIE_PAGE_SIZE_REPORT";
