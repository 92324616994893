 <!-- <nz-radio-group [(ngModel)]="urlActive" nzSize="small" (ngModelChange)="onChangeModel($event)" [ngClass]="'sb-menu-tab'">
    <ng-container *ngFor="let item of MenuData; let p=index">
        <ng-container *ngIf="item.permision">
            <label  nz-radio-button [nzValue]="item.url" *sbAuthorize="'{{item.permision}}'">
                <span nz-icon [nzType]="item.icon" nzTheme="outline" style="margin-right: 5px;"></span>
                <span>{{item.ten}}</span></label>
        </ng-container>
        <ng-container *ngIf="!item.permision">
            <label  nz-radio-button [nzValue]="item.url" >
                <span nz-icon [nzType]="item.icon" nzTheme="outline" style="margin-right: 5px;"></span>
                <span>{{item.ten}}</span>
            </label>
        </ng-container>
      
    </ng-container>
   
</nz-radio-group>  -->


<ul class="menu-tab" *ngIf="MenuData.length > 0">
    <li [ngClass]="ActiveTab(item)" *ngFor="let item of MenuData; let p=index">
        <a  (click)="onClick(item)">
            <span nz-icon [nzType]="item.icon" nzTheme="outline" style="margin-right: 5px;"></span>
            {{item.ten}}
            <span nz-icon nzType="down" nzTheme="outline" *ngIf="item.submenu.length > 0"></span>
        </a>
        <ng-container *ngIf="item.submenu.length > 0">
            <ul>
                <li [ngClass]="ActiveTab(item1)" *ngFor="let item1 of item.submenu;"><a  class="documents" (click)="onClick(item1)">{{item1.ten}}</a></li>
            </ul> 
        </ng-container>
      
    </li>
</ul> <!-- end .menu -->

