
import { AfterViewChecked, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';
import { concat, Observable, of, Subject, map, Subscription } from 'rxjs';
import { MenuConstantService } from '../../services/menu-constant.service';

export class MenuTabItem {
  ten: string;
  url: string;
  permision: string;
  icon: string;
  submenu: Array<any>
}


@Component({
  selector: 'sb-menu-tab',
  templateUrl: './sb-menu-tab.component.html',
  styleUrls: ['./sb-menu-tab.component.scss'],
})
export class SbMenuTabComponent implements OnInit, OnDestroy {

  MenuData : Array<MenuTabItem>;
  isLoading = false;
  MenuRouter: Array<any> = [];
  urlActive: string;

  private menuSub: Subscription;
  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute,
    private themeService: MenuConstantService, 

  ) { }


  ngOnDestroy(): void {
    this.menuSub.unsubscribe();
  }


  ngOnInit(): void {
    this.urlActive = this.router.url;
   
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.MenuData = [];
        this.urlActive = event.url;
        this.urlActive = this.xulyUrl(this.urlActive);
        this.FindMenuTab(this.MenuRouter);
       
      }
    });

    this.activatedRoute.children.forEach(child => {
      child.params.subscribe(params => {
        if(params){
          Object.keys(params).forEach(key => {
            this.urlActive = this.urlActive.replace("/"+ params[key],'');
          });
          this.urlActive = this.xulyUrl(this.urlActive);
        }
      })
    });
   
    this.menuSub = this.themeService.isMenuChanges.subscribe(
      (mn) => {
        this.MenuData = [];
        this.MenuRouter = mn;
        this.FindMenuTab(this.MenuRouter);
      }
    );
     
  }

  xulyUrl(url: string): string {
    let thirdSlashIndex = this.findThirdSlashIndex(url);
    if (thirdSlashIndex !== -1) {
            // Cut the string from the third '/' character
      return url.substring(0, thirdSlashIndex);
    }
    return url;
  }

  findThirdSlashIndex(input: string): number {
    let count = 0;
    for (let i = 0; i < input.length; i++) {
      if (input[i] === '/') {
        count++;
        if (count === 3) {
          return i;
        }
      }
    }
    return -1; // Return -1 if less than three slashes are found
  }

  onChangeModel(event){
     
      this.router.navigate([event]);
  }

  ActiveTab(tabItem){
    if(this.urlActive){
      let item = this.urlActive + '/';
      //Kiểm tra menu hiện tại và menu con có active không
      return (item.indexOf(tabItem.url + '/') > -1 || (tabItem.submenu && tabItem.submenu.filter(b => item == (b.url + '/')).length > 0 ))  ? 'active' : '';
    }else{
      return '';
    }
  
  }

  FindMenuTab(menu){
    if(menu && menu.length > 0){
      menu.forEach(element => {
        if(element.tabmenu && element.tabmenu.findIndex(b => this.urlActive == b.path  && b.isMenuTab == true)>-1){
          this.MenuData = element.tabmenu.map(b => {
            let subtab = [];
            if(b.submenu && b.submenu.length > 0){
              subtab = b.submenu.map(c => {
                return {
                  ten: c.title,
                  url: c.path,
                  permision: null,
                  icon: c.icon,
                  submenu: []
                }
              });
            }
            return {
              ten: b.title,
              url: b.path,
              permision: null,
              icon: b.icon,
              submenu: subtab
            }
          });
          return;
        }
        if(element.submenu && element.submenu.length > 0){
          this.FindMenuTab(element.submenu);
        }
      });
    }
    
  }

  onClick(item){
    if(item.url){
      this.router.navigate([item.url]);
    }
  }

}
