import { Pipe, PipeTransform } from '@angular/core';
import { FileService } from 'src/app/services/file.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image'
})
export class DisplayImagePipe implements PipeTransform {
  constructor() {
  }

  transform(value: string, size = 'o'): any {
    return `${environment.SERVICE_API_FILE}Files/GetFile?id=${value}&size=${size}`
  }
}
