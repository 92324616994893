import { NgSelectModule } from '@ng-select/ng-select';
import { PercentPipe, VndPipe } from './pipes/vnd.pipe';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { EcoAuthorizeDirective } from '../guard/role.guard';
import { SbCheckPhoneDirective } from '../guard/phone.guard';
import { OnlyNumberDirective } from './directives/numeric.directive';
import { DisplayImagePipe } from './pipes/displayImage';
import { PhoneNumberCheckDirective } from './directives/phoneNumber.directive';
import { FormatMoneyDirective } from './directives/formatMoney.directive';
import { DisplayTimeWithoutSecond } from './pipes/displayTimeWithoutSecond.pipe';
import { TimeOnlyPipe } from './pipes/time-only.pipe';
import {
  EnumMaTrangThai,
  EnumStatusProcessd,
  LoaiQuyType,
  PatternSmsType,
  ProductTypePipe,
  SendStatusPipe,
  TopSalePipe,
  TrangThaiPheDuyetSmsPipe,
} from './pipes/trang-thai-PheDuyetSms.pipe';
import { SafeHtmlPipe } from './pipes/html.pipe';
import { GenderPipe } from './pipes/genderDisplay.pipe';
import { NgxMaskDirective } from 'ngx-mask';
import { InputMinValidatorDirective } from './directives/InputMinValidator.directive';
import { DotdotdotPipe } from './pipes/dotdotdot.pipe';
import { CountUpDirective } from './directives/count-up.directive';
import { PaginationModule } from './components/pagination/pagination.module';
import { RoundPipe } from './pipes/round.pipe';
import { SaveOldValueDirective } from './directives/saveOldValueDirective.directive';
import { NzSelectDefaultDirective } from './directives/nzselect.directive';
import { RemoveNegativeSignPipe } from './pipes/remove-negative-sign.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzIconModule,
    PerfectScrollbarModule,
    SearchPipe,
    VndPipe,
    NzModalModule,
    NzNotificationModule,
    NzSpinModule,
    EcoAuthorizeDirective,
    SbCheckPhoneDirective,
    OnlyNumberDirective,
    InputMinValidatorDirective,
    DisplayImagePipe,
    PhoneNumberCheckDirective,
    FormatMoneyDirective,
    NgSelectModule,
    DisplayTimeWithoutSecond,
    TrangThaiPheDuyetSmsPipe,
    SendStatusPipe,
    PatternSmsType,
    GenderPipe,
    DotdotdotPipe,
    NgxMaskDirective,
    ProductTypePipe,
    TopSalePipe,
    CountUpDirective,
    LoaiQuyType,
    PaginationModule,
    EnumStatusProcessd,
    EnumMaTrangThai,
    RoundPipe,
    SaveOldValueDirective,
    NzSelectDefaultDirective,
    PercentPipe,
    RemoveNegativeSignPipe,
    InfiniteScrollModule,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    PerfectScrollbarModule,
    NzModalModule,
    NgxMaskDirective,
    InfiniteScrollModule,
  ],
  declarations: [
    SearchPipe,
    VndPipe,
    PercentPipe,
    EcoAuthorizeDirective,
    SbCheckPhoneDirective,
    InputMinValidatorDirective,
    OnlyNumberDirective,
    DisplayImagePipe,
    PhoneNumberCheckDirective,
    FormatMoneyDirective,
    DisplayTimeWithoutSecond,
    TimeOnlyPipe,
    TrangThaiPheDuyetSmsPipe,
    SafeHtmlPipe,
    SendStatusPipe,
    PatternSmsType,
    GenderPipe,
    DotdotdotPipe,
    ProductTypePipe,
    TopSalePipe,
    CountUpDirective,
    LoaiQuyType,
    EnumStatusProcessd,
    EnumMaTrangThai,
    RoundPipe,
    SaveOldValueDirective,
    NzSelectDefaultDirective,
    RemoveNegativeSignPipe,
  ],
  providers: [CurrencyPipe, ThemeConstantService],
})
export class SharedModule {}
