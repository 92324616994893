import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';
@Injectable({
  providedIn: 'root',
})
export class CauHinhContactService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.SERVICE_API_CONTACT}api/CauHinh`);
  }

  GetCauHinhInit(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhInit`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetCauHinhQuanLyNguonDuLieu(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhQuanLyNguonDuLieu`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetCauHinhMKT(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhMKT`;
    return this.get<ResponseData>(url).toPromise();
  }

  Get(): Promise<ResponseData> {
    const url = `${this.table}/Get`;
    return this.get<ResponseData>(url).toPromise();
  }

  GetCauHinhChoPhepTaoContactKhiCuocGoiDen(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhChoPhepTaoContactKhiCuocGoiDen`;
    return this.post<ResponseData>(url, null).toPromise();
  }

  SaveCauHinhInit(model): Promise<ResponseData> {
    const url = `${this.table}/SaveCauHinhInit`;
    return this.post<ResponseData>(url, model).toPromise();
  }
  LuuCauHinhKhoSo(model): Promise<ResponseData> {
    const url = `${this.table}/LuuCauHinhKhoSo`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  GetCauHinhKhoSo(): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhKhoSo`;
    return this.get<ResponseData>(url).toPromise();
  }

  Save_SuperAdmin(model): Promise<ResponseData> {
    const url = `${this.table}/Save_SuperAdmin`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  GetCauHinhSuperAdmin(model): Promise<ResponseData> {
    const url = `${this.table}/GetCauHinhSuperAdmin`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  DsCauHinhGiaoHangChuyenCskh(): Promise<ResponseData> {
    const url = `${this.table}/DsCauHinhGiaoHangChuyenCskh`;
    return this.post<ResponseData>(url, null).toPromise();
  }
  SaveCauHinhGiaoHangChuyenCskh(model): Promise<ResponseData> {
    const url = `${this.table}/SaveCauHinhGiaoHangChuyenCskh`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  CheckQuyenXuatExcel(): Promise<ResponseData> {
    const url = `${this.table}/CheckQuyenXuatExcel`;
    return this.get<ResponseData>(url).toPromise();
  }
  SaveQuanLyNguonDuLieu(model): Promise<ResponseData> {
    const url = `${this.table}/SaveQuanLyNguonDuLieu`;
    return this.post<ResponseData>(url, model).toPromise();
  }
  SaveCauHinhMKT(model): Promise<ResponseData> {
    const url = `${this.table}/SaveCauHinhMKT`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  getLstTrangThaiCuocGoi(): Promise<ResponseData> {
    const url = `${this.table}/getLstTrangThaiCuocGoi`;
    return this.get<ResponseData>(url).toPromise();
  }

}
