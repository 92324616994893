<div class="clearfix btn-handler" *ngIf="onlyImage">
  <nz-upload [nzAction]="urlApi" nzListType="picture-card" [nzMultiple]="isMultiple" [nzRemove]="onRemove"
    (nzChange)="handleChange($event)" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload"
    [nzShowButton]="onShowUpload()" [nzDownload]="onDownLoad" [nzPreview]="handlePreview" [nzAccept]="filterImageType">
    <div>
      <span nz-icon nzType="plus"></span>
      <div style="margin-top: 8px">Upload</div>
    </div>
  </nz-upload>
  <nz-modal [nzVisible]="previewVisible" [nzWidth]="widthModal" [nzContent]="modalContent" [nzFooter]="null"
    (nzOnCancel)="previewVisible = false">
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
  </nz-modal>
</div>

<div class="clearfix btn-handler" *ngIf="!onlyImage">
  <nz-upload [nzAction]="urlApi" nzListType="text" [nzMultiple]="isMultiple" [nzRemove]="onRemove"
    (nzChange)="handleChange($event)" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload"
    [nzShowButton]="onShowUpload()" [nzDownload]="onDownLoad" nzAccept="application/pdf">
    <div style="display: inline-block; ">
      <span nz-icon style="color: #3f87f5" nzType="upload"></span>&nbsp;
      <a href="javascript:void(0)">Chọn file pdf</a>
    </div>
  </nz-upload>
</div>
