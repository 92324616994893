<perfect-scrollbar class="side-nav" sideNav>
  <div id="logo" class="logo logo-dark">
    <a href="javascript:void(0);" (click)="onViewHome()">
        <img class="logo-fold" src="assets/images/logo/logo-sandbox.png?v=2" alt="Logo">
    </a>
  </div>

  <div style="padding:5px">

    <ul class="sb-menu" [ngClass]="{
      'ant-menu-inline-collapsed': isFolded,
      'ant-menu-dark' : isSideNavDark
  }">
<li class="sb-menu-item" [ngClass]="{'sb-menu-active' : item.active }" (click)="onMenuActive(item,$event)"
*ngFor="let item of menuItems; let p=index"
[routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
[routerLinkActiveOptions]="{exact: true}">
<a [routerLink]="item.path"  class="sb-link-item" *ngIf="item.submenu.length === 0">
  <!-- <i  [ngClass]="item.icon"  [style]="{'color' : item.iconColor}"></i> -->
  <ng-container *ngIf="item.icon">
    <div [ngClass]="item.icon
    ? 'sb-menu-left ' + item.icon
    : ''" ></div>
  </ng-container>

  <span>{{item.title}}</span>
</a>
<a href="javascript:void(0);" class="sb-link-item" *ngIf="item.submenu.length > 0">
  <!-- <i  [ngClass]="item.icon"  [style]="{'color' : item.iconColor}"></i> -->

  <ng-container *ngIf="item.icon">
    <div [ngClass]="item.icon
    ? 'sb-menu-left ' + item.icon
    : ''" ></div>
  </ng-container>
  <span>{{item.title}}</span>

  <div class="sb-menu-lv2" *ngIf="item.submenu.length > 0">


      <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" style="background: none;">
      <li
      [ngClass]="getClassMenu1(item2)"
      *ngFor="let item2 of item.submenu; let p=index"
      [routerLinkActive]="item2.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
      [routerLinkActiveOptions]="{exact: true}">
      <a href="javascript:void(0);" class="ant-menu-submenu-title link-item" *ngIf="item2.submenu.length > 0">
        <ng-container *ngIf="item2.icon">
          <span [ngClass]="item2.icon
          ? 'sb-menu-image ' + item2.icon
          : ''" ></span>
        </ng-container>

        <span>{{item2.title}}</span>
        <i class="ant-menu-submenu-arrow"></i>
      </a>
      <a [href]="item2.path" (click)="onMenuPanelClose($event,item2)" class="ant-menu-submenu-title link-item" *ngIf="item2.submenu.length === 0">
        <ng-container *ngIf="item2.icon">
          <span [ngClass]="item2.icon
          ? 'sb-menu-image ' + item2.icon
          : ''" ></span>
        </ng-container>
        <span>{{item2.title}}</span>
      </a>
      <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item2.submenu.length > 0">
        <li
          [ngClass]="getClassMenu(subItem)"
          *ngFor="let subItem of item2.submenu; let i=index"
          (mouseenter)="subItem.submenu.length>0 ?onHover($event,subItem.title,i):''"
          (mouseleave)="subItem.submenu.length>0 ?outHover($event,subItem.title,i):''" id={{subItem.title}}{{i}}
          [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
          [routerLinkActiveOptions]="{exact: true}">
          <a href="javascript:void(0);" class="ant-menu-submenu-title" *ngIf="subItem.submenu.length > 0">
            <span >{{subItem.title}}</span>
            <i class="ant-menu-submenu-arrow"></i>
          </a>
          <a [href]="subItem.path" (click)="onMenuPanelClose($event,subItem)" *ngIf="subItem.submenu.length === 0">
            <span >{{subItem.title}}</span>
          </a>

        </li>
      </ul>
      </li>
      </ul>

  </div>

  <div class="sb-modal-mask" *ngIf="item.submenu.length > 0" (click)="onMenuPanelClose($event,null)"></div>
</a>

<span class="arrow-up" *ngIf="item.submenu.length > 0"></span>


</li>
</ul>

  </div>

</perfect-scrollbar>
<div class="side-nav1">
  <!-- <a  [routerLink]="'/caidat/dangky-tien-ich'" target="_blank" class="nav-tienich">
    <i class="fa-brands fa-windows" style="color: #1E86FF; font-size: 15px;"></i>
    Tiện ích mở rộng
</a> -->
</div>
