import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ChinhanhService extends BaseService {
  constructor(public httpClient: HttpClient) {
    super(httpClient, `${environment.CORE_API}api/QuanTriArea/ChiNhanh`);
  }

  layQuocGia(id?: Guid): Promise<ResponseData> {
    let url = `${environment.CORE_API}api/Common/LayQuocGia`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  layTinhThanh(idQuocGia?: Guid): Promise<ResponseData> {
    const url = `${environment.CORE_API}api/Common/LayTinhThanh/${idQuocGia ?? Guid.EMPTY
      }`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  layQuanHuyen(idTinhThanh?: Guid): Promise<ResponseData> {
    let url = `${environment.CORE_API}api/Common/LayQuanHuyen`;

    if (idTinhThanh)
      url = `${environment.CORE_API}api/Common/LayQuanHuyen/${idTinhThanh}`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  layPhuongXa(idQuanHuyen?: Guid): Promise<ResponseData> {
    let url = `${environment.CORE_API}api/Common/LayPhuongXa`;

    if (idQuanHuyen)
      url = `${environment.CORE_API}api/Common/LayPhuongXa/${idQuanHuyen}`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }
  LayListChiNhanh() {
    const url = `${environment.CORE_API}api/ChiNhanh/LayListChiNhanh`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }
  LayListChiNhanhHasPermission() {
    const url = `${environment.CORE_API}api/ChiNhanh/LayListChiNhanhHasPermission`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }
  DonViLayListChiNhanh() {
    const url = `${environment.CORE_API}api/ChiNhanh/DonViGetListChiNhanh`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }
  GetChiNhanhTheoDonVi() {
    const url = `${environment.CORE_API}api/ChiNhanh/GetChiNhanhTheoDonVi`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }
  LayListChiNhanhTheoRole() {
    const url = `${environment.CORE_API}api/ChiNhanh/LayListChiNhanhTheoRole`;
    return this.httpClient.post<ResponseData>(url, null).toPromise();
  }
}
