export const MESSAGE = {
    TITLE_CONFIRM_LOGOUT: 'Bạn có chắc chắn muốn đăng xuất',
    SYSTEM_ERROR: 'Lỗi hệ thống',
    ERROR: 'Lỗi!!!',
    WARNING: 'Chú ý!!',
    SUCCESS: 'Thành công',
    ADD_SUCCESS: 'Thêm mới thành công',
    UPDATE_SUCCESS: 'Cập nhật thành công',
    DELETE_SUCCESS: 'Xóa thành công',
    DELETE_CONFIRM: 'Bạn có chắc chắn muốn xóa',
    PROCESS_SUCCESS: 'Chuyển xử lý thành công',
    SEND_NUMBER_SUCCESS: 'Chuyển cấp số thành công',
    REVERT_SUCCESS: 'Thu hồi thành công',
    RETURN_SUCCESS: 'Trả lại thành công',
    RETURN_RECEIVE_SUCCESS: 'Trả lời người gửi thành công',
    HAVE_COMMENT: 'Xin ý kiến thành công',
    COPY_SUCCESS: 'Sao chép thành công',
    IMPORT_NUMBER_SUCCESS: 'Cấp số thành công',
    IMPORT_NUMBER_SUCCESS_2: 'Lưu dữ liệu thành công',
    FINISH_SUCCESS: 'Kết thúc công việc thành công',
    SEND_SUCCESS: 'Trình văn bản thành công',
    RELEASE_SUCCESS: 'Phát hành thành công',
    USE_SUCCESS: 'Áp dụng giao diện thành công',
    APPROVE_SUCCESS:'Duyệt thành công',
    KY_THAY: 'Chuyển ký thay thành công',
    KY_THUALENH: 'Chuyển ký thừa lệnh thành công',
    KY_UYQUYEN: 'Chuyển ký ủy quyền thành công',
    MAXIMUM_FILE_UPLOAD: 'File đính kèm không vượt quá 20MB',
    GETNUMBER_SUCCESS: 'Xin số thành công',
    TRINHBOTAICHINH_SUCCESS: 'Trình bộ tài chính thành công',
    BUTPHE_SUCCESS: 'Cho ý kiến thành công',
    YEUCAUHOANTHIEN_SUCCESS: 'Chuyển trả chuyên viên hoàn thiện tờ trình',
    GANBDS_SUCCESS: 'Gán bất động sản cho đại lý thành công',
    HAYCHONDUAN_WARNING: 'Hãy chọn dự án trước khi gán cho đại lý',
    EXIST_CAUTRUCDETHI_WARNING: 'Vui lòng tạo cấu trúc đề thi trước khi sinh câu hỏi',
    GANBDS_DOTBAN_SUCCESS: 'Gán bất động sản cho đợt bán thành công',
    DATE_ERROR: "Ngày bắt đầu phải nhỏ hơn Ngày kết thúc",
    LOCKCAN_SUCCESS: "Lock căn thành công",
    UPUYNHIEMCHI_SUCCESS: "Up ủy nhiệm chi thành công",
    IDDUANREQUIRED_ERROR: "Bạn hãy chọn dự án",
    HOANTHIENHOPDONG_SUCCESS: "Hoàn thiện hợp đồng thành công",
    VAOTIEN_SUCCESS: "Vào tiền thành công",
    BANGIAO_SUCCESS: "Bàn giao thành công",
    THAYDOITRANGTHAIDONHANGSALE_ERROR: "Hãy thay đổi trạng thái đơn hàng sale trước khi lưu",
    THAYDOITRANGTHAIBDS_ERROR: "Hãy thay đổi trạng thái bds trước khi lưu",
    GANBDSSALE_SUCCESS: 'Gán bất động sản cho sale thành công',
    DANGKY_SUCCESS: 'Đăng ký thành công',
    PHEDUYET_SUCCESS: 'Phê duyệt thành công',
    HUYPHEDUYET_SUCCESS: 'Hủy phê duyệt thành công',
    GIAHAN_SUCCESS: 'Gia hạn thành công',
    YEUCAUGIAHAN_SUCCESS: 'Yêu cầu gia hạn thành công',
    CHONGIO_SUCCESS: "Chọn giỏ thành công",
  }
