import * as moment from 'moment';

export class UserLogin {
  id: string;
  displayName: string;
  userName: string;
  isSuperUser: boolean;
  avatarUrl?: string;

  homePage?: string;
  isTest?: boolean;
  donViId: string;
  roles: Array<any> = [];
  permisions: Array<any> = [];
  roleCodes: Array<any> = [];
  tenChiNhanh?: string;

  isAdminDaoTao?: boolean;
  isAdmin?: boolean;
  isAdNhieuChiNhanh?: boolean;
  isChuDauTu?: boolean;
  isDaiLy?: boolean;
  isDonViThuocDaiLy?: boolean;
  isKeToanChuDauTu?: boolean;
  isKeToanDaiLy?: boolean;
  isMultipleUnit?: boolean;
  isSale?: boolean;
  isCSKH?: boolean;
  kieuLoai?: number;
  pages: Array<any> = [];
  isChuHo?: boolean;
  maNganhNghe?: string;
  constructor(initObj: any) {
    if (initObj) {
      for (var key in initObj) {
        this[key] = initObj[key];
      }
    }
  }
  idDuAn: string;
  maDuAn: string;
  idNhaMau: string;

  idChiNhanh?: string;
  listTienIch: Array<any> = [];
  listTienIch_BE: Array<any> = [];
  public isHeThongKeToan() {
    return this.roleCodes.includes('HT_KT');
  }

  public isHeThongCSKH() {
    return this.roleCodes.includes('HT_CSKH');
  }

  public isThuNgan() {
    return this.roleCodes.includes('DV_QLDV');
  }

  public isAdChiNhanh() {
    return this.roleCodes.includes('DV_AD_CN');
  }

  public isAdDonVi() {
    return this.roleCodes.includes('DV_AD_TONG');
  }

  public isDVQuanLySale() {
    return this.roleCodes.includes('DV_QL_SALE');
  }

  public isDVSaleLead() {
    return this.roleCodes.includes('DV_SALE_LEAD');
  }

  public isDVSale() {
    return this.roleCodes.includes('DV_SALE');
  }

  public isDVQuanLyLDV() {
    return this.roleCodes.includes('DV_QLDV');
  }

  public checkTienIch(maTienIch) {
    if (
      this.listTienIch_BE == null ||
      this.listTienIch_BE == undefined ||
      this.listTienIch_BE.length == 0
    )
      return false;
    let check = this.listTienIch_BE.find(
      (x) =>
        x.maTienIch == maTienIch &&
        (x.ngayHetHan == null ||
          moment(x.ngayHetHan).format('YYYY-MM-DD') >=
            moment().format('YYYY-MM-DD'))
    );
    if (check == undefined) return false;
    else return true;
  }
}
