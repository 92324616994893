import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class MenuConstantService {
    
    // Theme Config
    isMenu: Array<any> = [];
   

    private currentMenu = new BehaviorSubject(this.isMenu);
    isMenuChanges = this.currentMenu.asObservable();

    changeMenuLoad(menu: Array<any>) {
        this.currentMenu.next(menu);
    }
}