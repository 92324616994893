export enum TimeZones {
  Pacific_Midway = 'Pacific/Midway',
  America_Adak = 'America/Adak',
  Etc_GMT_10 = 'Etc/GMT+10',
  Pacific_Guam = 'Pacific/Guam',
  America_Denver = 'America/Denver',
  America_Chicago = 'America/Chicago',
  America_New_York = 'America/New_York',
  America_Indianapolis = 'America/Indianapolis',
  Europe_London = 'Europe/London',
  Europe_Berlin = 'Europe/Berlin',
  Europe_Paris = 'Europe/Paris',
  Asia_Tokyo = 'Asia/Tokyo',
  Asia_Shanghai = 'Asia/Shanghai',
  Asia_Ho_Chi_Minh = 'Asia/Ho_Chi_Minh',
  Australia_Sydney = 'Australia/Sydney',
  Africa_Johannesburg = 'Africa/Johannesburg'
};
