import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseData } from 'src/app/models/response';
import { BaseService } from '../base.service';
import { IDataClickToCallMyHub, MyHubService } from './myhub.service';
import { OmiCallDataRecive, OmiCallService } from './omicall.service';
import { SwitchBoardCode } from 'src/app/shared/Enums/switchboard';
import { UserService } from '../core/user.service';
import { BehaviorSubject, finalize, map, of, skip } from 'rxjs';
import { TimeZones } from 'src/app/shared/Enums/TimeZoneEnum';
import { getCurrentTimeByTimeZone } from 'src/app/shared/helper/ultility';

@Injectable({
  providedIn: 'root'
})

export class TongDaiUserService extends BaseService {
  private static infoUserSwitchboard: IInfoUserSwitchboard = null;
  private static user_data: any = null;
  refreshing = false;
  infoUserSwitchboard$: BehaviorSubject<IInfoUserSwitchboard> = new BehaviorSubject<IInfoUserSwitchboard>(
    { IsHaveSwitchboard: false }
  )
  constructor(
    public httpClient: HttpClient,
    public _myHubService: MyHubService,
    public _omiCallService: OmiCallService,
    public _userService: UserService,
  ) {
    super(httpClient, `${environment.SERVICE_API_SWITCHBOARD}api`)
    this._omiCallService.isInitOmiSDK$.subscribe((rs) => {
      if (rs == false) {
        this.setInfoUserSwitchboard({ IsHaveSwitchboard: false });
        this.infoUserSwitchboard$.next(TongDaiUserService.infoUserSwitchboard);
      }
      if (rs == true) {
        this.setInfoUserSwitchboard({ IsHaveSwitchboard: true, Data: TongDaiUserService.user_data });
        this.infoUserSwitchboard$.next(TongDaiUserService.infoUserSwitchboard);
      }
    });

    this._myHubService.isInitMyHub$.subscribe((rs) => {
      if (rs == false) {
        this.setInfoUserSwitchboard({ IsHaveSwitchboard: false });
        this.infoUserSwitchboard$.next(TongDaiUserService.infoUserSwitchboard);
      }
      if (rs == true) {
        this.setInfoUserSwitchboard({ IsHaveSwitchboard: true, Data: TongDaiUserService.user_data });
        this.infoUserSwitchboard$.next(TongDaiUserService.infoUserSwitchboard);
      }
    });
  }

  TongDaiUserChiTiet(body): Promise<ResponseData> {
    const url = `${this.table}/TongDaiUser/TongDaiUserChiTiet`;
    return this.PostRequest(url, body);
  }

  TongDaiUserDanhSach(body) {
    const url = `${this.table}/TongDaiUser/TongDaiUserDanhSach`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  TongDaiUserLayAllUserPartner(body) {
    const url = `${this.table}/TongDaiUser/TongDaiUserLayAllUserPartner`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  TongDaiUserTaoMoi(body) {
    const url = `${this.table}/TongDaiUser/TongDaiUserTaoMoi`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetListUserConnective(body) {
    const url = `${this.table}/TongDaiUser/GetListUserConnective`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  DeleteUserConnective(body) {
    const url = `${this.table}/TongDaiUser/DeleteUserConnective`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetInfoLoginPartnerByCrmUser() {
    const url = `${this.table}/Common/GetInfoLoginPartnerByCrmUser`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  setInfoUserSwitchboard(infoUserSwitchboard: IInfoUserSwitchboard) {
    TongDaiUserService.infoUserSwitchboard = infoUserSwitchboard;
  }

  getInfoUserSwitchboard() {
    return TongDaiUserService.infoUserSwitchboard;
  }

  UpdateUserConnective(body) {
    const url = `${this.table}/TongDaiUser/UpdateUserConnective`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  ChangeUserConnective(body) {
    const url = `${this.table}/TongDaiUser/ChangeUserConnective`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  //#region  v2
  GetCurrentInfoUserDonVi_V2(body) {
    const url = `${this.table}/TongDaiIpUser/GetCurrentInfoUserDonVi`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  ThayDoiUserTongDaiIp_V2(body) {
    const url = `${this.table}/TongDaiIpUser/ThayDoiUserTongDaiIp`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetDanhSachUserTongDaiDaMapCrm() {
    const url = `${this.table}/TongDaiIpUser/GetDanhSachUserTongDaiDaMapCrm`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  GetThongTinSoTienUserTongDai(body) {
    const url = `${this.table}/TongDaiIpUser/GetThongTinSoTienUserTongDai`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetDanhSachUserBenCrm(body) {
    const url = `${this.table}/TongDaiIpUser/GetDanhSachUserBenCrm`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  MapUserDonViVoiUserTongDai(body) {
    const url = `${this.table}/TongDaiIpUser/MapUserDonViVoiUserTongDai`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetCauHinhSoTienUserTrenThang() {
    const url = `${this.table}/TongDaiIpUser/GetCauHinhSoTienUserTrenThang`;
    return this._httpClient.get<ResponseData>(url).toPromise();
  }

  GetDanhSachUserDaMapTongDai(body) {
    const url = `${this.table}/TongDaiIpUser/GetDanhSachUserDaMapTongDai`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  CalculateSoTienKhiThayDoiUser(body) {
    const url = `${this.table}/TongDaiIpUser/CalculateSoTienKhiThayDoiUser`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }

  CapNhatUserXoaTongDai(body) {
    const url = `${this.table}/TongDaiIpUser/CapNhatUserXoaTongDai`;
    return this._httpClient.post<ResponseData>(url, body).toPromise();
  }
  //#endregion

  GetInfoUserSwitchboard() {
    if (TongDaiUserService.infoUserSwitchboard) {
      return of(TongDaiUserService.infoUserSwitchboard);
    } else {
      if (!this.refreshing) {
        this.refreshing = true;
        const url = `${this.table}/Common/GetInfoLoginPartnerByCrmUser`;
        return this.get<ResponseData>(url).pipe(
          map((res: ResponseData) => {
            this.refreshing = false;
            if (res != null && res.success && res.data != null) {
              TongDaiUserService.user_data = res?.data;
              // this.setInfoUserSwitchboard({
              //   IsHaveSwitchboard: true,
              //   Data: res.data,
              // });
              let dataLogin = res.data.dataLogin;
              if (res.data.switchBoardCode === SwitchBoardCode.OmiCall) {
                if (res.data.dataLogin != null) {
                  this._omiCallService.setUpInit({
                    domain: dataLogin?.domain ?? "domain",
                    username: dataLogin?.sip_user ?? "username",
                    password: dataLogin?.password ?? "password",
                    // domain: "domain",
                    // username: "username",
                    // password: "password",
                  });
                }
              }
              if (res.data.switchBoardCode === SwitchBoardCode.MyHub) {
                this._myHubService.setUpInit();
              }
              this.infoUserSwitchboard$.next(TongDaiUserService.infoUserSwitchboard);
              return TongDaiUserService.infoUserSwitchboard;
            } else {
              this.setInfoUserSwitchboard({
                IsHaveSwitchboard: false,
              })
              this.infoUserSwitchboard$.next(TongDaiUserService.infoUserSwitchboard);
              return TongDaiUserService.infoUserSwitchboard;
            }
          }),
          finalize(() => {
            this.refreshing = false;
          })
        );
      } else {
        let data: IInfoUserSwitchboard = {
          IsHaveSwitchboard: false
        }
        return of(data)
      }
    }
  }

  ClickToCall(phone, data) {
    if (TongDaiUserService.infoUserSwitchboard != null && TongDaiUserService.infoUserSwitchboard.IsHaveSwitchboard) {
      if (TongDaiUserService.infoUserSwitchboard?.Data?.switchBoardCode === SwitchBoardCode.MyHub) {
        this._myHubService.setShowListHotline();
        let dataMyHub: IDataClickToCallMyHub = {
          To: phone,
          IdContact: data?.IdContact,
          IdDonHang: data?.IdDonHang,
          IdKhachHang: data?.IdKhachHang,
          NgayGoiDien: getCurrentTimeByTimeZone(TimeZones.Asia_Ho_Chi_Minh),
          NgayTaoContact: data?.NgayTaoContact,
          IdTacNghiepCan: data?.IdTacNghiepCan,
        }
        this._myHubService.sendDataCallSubject(dataMyHub);
      } else if (TongDaiUserService.infoUserSwitchboard?.Data?.switchBoardCode === SwitchBoardCode.OmiCall) {
        let dataRecive: OmiCallDataRecive = {
          CrmUnitId: data?.crmUnitId,
          PropName1: "IdContact",
          PropValue1: data?.IdContact,
          PropName2: "IdTacNghiepCan",
          PropValue2: data?.IdTacNghiepCan,
          PropName3: "NgayTaoContact",
          PropValue3: data?.NgayTaoContact,
          PropName4: "IdDonHang",
          PropValue4: data?.IdDonHang,
          PropName5: "IdKhachHang",
          PropValue5: data?.IdKhachHang,
          SwitchBoardCode: SwitchBoardCode.OmiCall,
          UserConnectivePropName1: 'domain-fushion',
          UserConnectivePropValue1: TongDaiUserService.infoUserSwitchboard?.Data?.dataLogin?.domain,
          PartnerUserCallName: TongDaiUserService.infoUserSwitchboard?.Data?.dataLogin?.sip_user,
        }
        this._omiCallService.ClickToCall(phone, dataRecive);
      }
    } else {
    }
  }

  ClickToShow(phone, data) {
    if (TongDaiUserService.infoUserSwitchboard != null && TongDaiUserService.infoUserSwitchboard.IsHaveSwitchboard) {
      if (TongDaiUserService.infoUserSwitchboard?.Data?.switchBoardCode === SwitchBoardCode.MyHub) {
        // this._myHubService.setShowListHotline();
      } else if (TongDaiUserService.infoUserSwitchboard?.Data?.switchBoardCode === SwitchBoardCode.OmiCall) {

      }
    } else {
    }
  }
}
export type IInfoUserSwitchboard = {
  IsHaveSwitchboard: boolean;
  Data?: any
}
