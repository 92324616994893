import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/models/response';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public httpClient: HttpClient, private _userService: UserService) {

  }

  // lấy thông báo theo phân trang
  getNotificationUser() {
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/GetsByUserId`;
    return this.httpClient.get<ResponseData>(url);
  }

  //số lượng thông báo chưa xem
  countUnReadNotification(){
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/UnRead/1`;
    return this.httpClient.get<ResponseData>(url);
  }

  readNotification(id){
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/Read/${id}`;
    return this.httpClient.post<ResponseData>(url, null);
  }

  readAllNotification(){
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/ReadAll`;
    return this.httpClient.get<ResponseData>(url);
  }

  unSubscribeTopic(id){
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/unsubcribeall?userid=`+id;
    return this.httpClient.post<ResponseData>(url,null);
  }

  registerToken(token): Promise<object> {
    const data = {
      token: token,
      userId: this._userService.getUserLogin().id,
      appId: "WEBSITE",
      "clientId": "99"
    }
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/registertoken`;
    return this.httpClient.post<object>(url, data).toPromise();
  }

  deleteToken(token) {
    const data = {
      token: token,
      userId: this._userService.getUserLogin().id,
      appId: "WEBSITE",
      clientId: 0
    }
    const url = `${environment.SERVICE_NOTI_API}api/Notifications/removetoken`;
    return this.httpClient.post<ResponseData>(url, data);
  }
}
