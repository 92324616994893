import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzConfigKey, NzConfigService } from 'ng-zorro-antd/core/config';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { OnChangeType, OnTouchedType } from 'ng-zorro-antd/core/types';

const NZ_CONFIG_MODULE_NAME: NzConfigKey = 'datePicker';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {

  readonly _nzModuleName: NzConfigKey = NZ_CONFIG_MODULE_NAME;

  inputValue: Date;
  value: any;
  @Input() placeHolder : string;

  private destroy$ = new Subject<void>();

  onChange: OnChangeType = _value => { };
  onTouched: OnTouchedType = () => { };

  @Input() nzDisabled = false;

  @Output() change = new EventEmitter<any>();

  @Input() nzFormat = 'dd/MM/yyyy'

  @Input() nzShowTime = false
  @Input() nzAllowClear = true
  @Input() styleCss = 'width: 100%'
  constructor(
    public nzConfigService: NzConfigService,
  ) {

  }

  ngOnInit(): void {
        if(this.nzShowTime ){
            if(!this.placeHolder){
                this.placeHolder = "dd/MM/yyyy HH:mm"
            }

        }else{
            if(!this.placeHolder){
                this.placeHolder ="dd/MM/yyyy"
            }
        }
  }

  ngOnDestroy(): void {

  }



  ngOnChanges(changes: SimpleChanges): void {

  }

  writeValue(value: Date): void {
    this.inputValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.nzDisabled = isDisabled;
  }

  updateModel(evt) {
    if (!evt || (evt && !evt.notFireEvent)) {
        this.onChange(evt);
        this.change.next(evt);
    }
  }

  onKeyupEnter(evt){
    if (!evt || (evt && !evt.notFireEvent)) {
      var value = (evt.target as HTMLInputElement).value;
      var result;
      if(this.nzShowTime){
         result = moment(value, "DD/MM/YYYY HH:mm");
      }else{
        result = moment(value, "DD/MM/YYYY");
      }

      if(result.isValid()){
        this.inputValue = result.toDate();
      }
      else{
        this.inputValue = null;
      }

      this.onChange(this.inputValue);
      if(this.inputValue){
        this.change.next(this.inputValue);
      }else{
        this.change.next(this.inputValue);
      }

    }
  }

}
