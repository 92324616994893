
import { AfterViewChecked, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectComponent } from 'ng-zorro-antd/select/select.component';
import { concat, Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-dropdown-donvi-one',
  templateUrl: './dropdown-donvi-one.component.html',
  styleUrls: ['./dropdown-donvi-one.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownDonViOneComponent),
      multi: true
    }
  ]
})
export class DropdownDonViOneComponent implements OnInit, ControlValueAccessor {
  selectModel : any;
  isLoading = false;
  people$: Observable<any[]>;
  peopleInput$ = new Subject<string>();
  ///deptType = 0 : đơn vị trong ngành
  ///deptType = 1 : đơn vị ngoài ngành
  ///deptType = 2 : đơn vị thuộc tổng cục
  ///deptType = 100 : nhóm đơn vị
  @Input() deptType: number = 1;
  @Input() donViSoanThaoId: any = "";
  @Input() minTermLength: number = 2;

  public onChange: (data: any) => void;
  public onTouched: () => void;
  constructor(
   
  ) { }
  writeValue(obj: any): void {
    this.selectModel = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }



  ngOnInit(): void {
      this.loadDonViNgoaiNganh();
  }


  trackByFn(item: any) {
    return item.id;
  }

  private loadDonViNgoaiNganh() {
    // this.people$ = concat(
    //   of([]), // default items
    //   this.peopleInput$.pipe(

    //     distinctUntilChanged(),
    //     tap(() => this.isLoading = true),
    //     switchMap(term => this.donViService.timTheoTuKhoa(term).pipe(
    //       map(rsp => rsp.data),
    //       catchError(() => of([])), // empty list on error
    //       tap(() => this.isLoading = false),

    //     ))
    //   )
    // );
  }

  onChangeDonVi(event) {
    if (this.onChange) {
      this.onChange(this.selectModel);
    }

  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.groupName.toLowerCase().indexOf(term) > -1;
  }


}
