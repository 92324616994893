import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { STORAGE_KEY } from '../constants/config';
import { MemCache } from '../constants/MemCache';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResponseData } from '../models/response';
import { catchError, Observable, tap } from 'rxjs';
import { NotificationService } from './core/notification.service';
import { UserService } from './core/user.service';
import { OmiCallService } from './swicthboard/omicall.service';
import { MyHubService } from './swicthboard/myhub.service';
import { TongDaiUserService } from './swicthboard/tong-dai-user.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public redirectUrl: string;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private userService: UserService,
    private _omiCallService: OmiCallService,
    private _myHubService: MyHubService,
    private _tongDaiUserService: TongDaiUserService
  ) { }

  login(body) {
    const url = `${environment.ACCOUNT_API}api/Authen/login`;
    return this.httpClient.post(url, body, { withCredentials: true });
  }

  GetUserUseCaptcha(body) {
    const url = `${environment.ACCOUNT_API}api/Authen/getuserusecaptcha`;
    return this.httpClient.post(url, body, { withCredentials: true });
  }


  loginEnc(body) {
    const url = `${environment.ACCOUNT_API}api/Authen/login-encrypt`;
    return this.httpClient.post(url, body, { withCredentials: true });
  }

  loginOtpEnc(body) {
    const url = `${environment.ACCOUNT_API}api/Authen/loginotp-encrypt`;
    return this.httpClient.post(url, body, { withCredentials: true });
  }


  refreshToken(body) {
    const url = `${environment.ACCOUNT_API}api/Authen/tokenrefresh`;
    return this.httpClient.post(url, {}, { withCredentials: true });
  }

  refreshTokenEnc(body) {
    const url = `${environment.ACCOUNT_API}api/Authen/tokenrefresh-encrypt`;
    return this.httpClient.post(url, body, { withCredentials: true });
  }

  timKiemNguoiDung(username) {
    const url = `${environment.ACCOUNT_API}api/Authen/TimKiemNguoiDung?username=${username}`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  saveToken(token: string) {
    return this.storageService.set(STORAGE_KEY.ACCESS_TOKEN, token);
  }

  saveTokenRefresh(token: string) {
    return this.storageService.set(STORAGE_KEY.ACCESS_TOKEN_REFRESH, token);
  }

  savePermison(role: Array<string>) {
    return this.storageService.set(STORAGE_KEY.ROLE, JSON.stringify(role));
  }

  getPermison(): Array<string> {
    return JSON.parse(this.storageService.get(STORAGE_KEY.ROLE));
  }

  getToken(): string {
    return this.storageService.get(STORAGE_KEY.ACCESS_TOKEN);
  }
  getTokenRefresh(): string {
    return this.storageService.get(STORAGE_KEY.ACCESS_TOKEN_REFRESH);
  }

  saveUser(user) {
    return this.storageService.set(STORAGE_KEY.USER_INFO, user);
  }

  getUser() {
    return this.storageService.get(STORAGE_KEY.USER_INFO);
  }

  saveRole(role: number) {
    return this.storageService.set(STORAGE_KEY.ROLE, role);
  }

  getRole(): number {
    return this.storageService.get(STORAGE_KEY.ROLE);
  }

  logoutV2(): Promise<any> {
    this.userService.ClearCurrentUser();
    const url = `${environment.ACCOUNT_API}api/Authen/logout`;
    return this.httpClient.post(url, {}, { withCredentials: true })
      .pipe(
        tap(data => {
          this._omiCallService.unregister();
          localStorage.clear();
          this.router.navigate(['login']);
          return data;
        }),
        catchError((error) => {
          console.log(`[logout] error "${error.message}"`);
          localStorage.clear();
          return this.router.navigate(['login']);
        }),

      )
      .toPromise();
  }


  logout(isUnAuthorize = false) {

    const url = `${environment.ACCOUNT_API}api/Authen/logout`;
    let tokenFireBase = localStorage.getItem("firebaseToken")
    this.httpClient.post(url, { token: tokenFireBase }, { withCredentials: true }).subscribe(rs => {

      // if(!isUnAuthorize){
      //   if(localStorage.getItem("firebaseToken")){
      //     this.notificationService.deleteToken(localStorage.getItem("firebaseToken")).subscribe(result=>{
      //       if(result.success) {
      //         this.userService.ClearCurrentUser();
      //         localStorage.clear();
      //         //clear cache
      //         MemCache.ListPagePermision = [];
      //         this.router.navigate(['login'])
      //       }
      //     })
      //   }
      // }
      this._tongDaiUserService.setInfoUserSwitchboard(null);
      this._omiCallService.resetOmiCall();
      this._myHubService.resetMyHub();
      this.userService.ClearCurrentUser();
      localStorage.clear();
      sessionStorage.clear();
      //clear cache
      MemCache.ListPagePermision = [];
      this.router.navigate(['login']);

    }, (err) => {
      this.userService.ClearCurrentUser();
      localStorage.clear();
      sessionStorage.clear();
      MemCache.ListPagePermision = [];
      this.router.navigate(['login']);
    });


  }

  getUserBySdt(sdt: string): Observable<ResponseData> {
    return this.httpClient.get<ResponseData>(`${environment.CORE_API}api/UserReset/GetUser?soDienThoai=${sdt}`);
  }

  veryOtp(body: any): Observable<ResponseData> {
    return this.httpClient.post<ResponseData>(`${environment.CORE_API}api/UserReset/VeriOtp`, body);
  }

  capNhatMatKhau(body): Observable<ResponseData> {
    return this.httpClient.post<ResponseData>(`${environment.CORE_API}api/UserReset/CapNhatMatKhau`, body);
  }

  layMaXacNhan(body): Observable<ResponseData> {
    return this.httpClient.post<ResponseData>(`${environment.ACCOUNT_API}api/Authen/TaoMaOtp`, body);
  }

  checkMaXacNhan(body): Observable<ResponseData> {
    return this.httpClient.post<ResponseData>(`${environment.ACCOUNT_API}api/Authen/CheckOTP`, body);
  }

  layMaXacNhanDangKy(body): Observable<ResponseData> {
    return this.httpClient.post<ResponseData>(`${environment.ACCOUNT_API}api/Authen/TaoMaOtp-DangKy`, body);
  }

  checkMaXacNhanDangKy(body): Observable<ResponseData> {
    return this.httpClient.post<ResponseData>(`${environment.ACCOUNT_API}api/Authen/CheckOTP-DangKy`, body);
  }
}
