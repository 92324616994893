import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ResponseData } from "src/app/models/response";
import { environment } from "src/environments/environment";
import { BaseService } from "../base.service";
import {
    BehaviorSubject,
    finalize,
    forkJoin,
    map,
    Observable,
    of,
  } from 'rxjs';

import { AccountFbService } from "../facebook/account-fb.service";
import { KieuNgayBaoCaoCuaHang, KieuNgayContact } from "src/app/shared/Enums/CommonEnums";

export class MyAppModule {
  s1: string
}

export class DropDownApiModule {
     kieuNgay: Array<{ id: string, ten: string }>
     kieuNgayBaoCaoCuaHang: Array<{ id: number, ten: string }>
  }

@Injectable({
  providedIn: 'root'
})
export class ConfigCoreService extends BaseService {

  public static moduleConfig: MyAppModule;
  public static dropDownConfig: DropDownApiModule;

  constructor(public httpClient: HttpClient,) {
    super(httpClient, `${environment.CORE_API}api/QuanTriArea/ConfigSystem`);
  }
  getWithTree(): Promise<ResponseData> {
    const url = `${this.table}/LayTatCaByGroup`
    return this.httpClient.get<ResponseData>(url, {}).toPromise();
  }
  getWithTreeSort(sort): Promise<ResponseData> {
    const url = `${this.table}/LayTatCaByGroupSort`
    return this.httpClient.post<ResponseData>(url, sort).toPromise();
  }
  getGroupCode(): Promise<ResponseData> {
    const url = `${this.table}/LayCacNhomCauHinh`
    return this.httpClient.get<ResponseData>(url, {}).toPromise();
  }

  getByCode(ma): Observable<ResponseData> {
    const url = `${this.table}/TimTheoMa/?ma=${ma}`
    return this.httpClient.get<ResponseData>(url);
  }

  getKieuNgay(): Observable<ResponseData> {
    const url = `${environment.CORE_API}api/Common/DropdownKieuNgayThongKe`
    return this.httpClient.get<ResponseData>(url);
  }

  ///init get key google
  loadModuleConfig() {

    if (!ConfigCoreService.moduleConfig) {
      const url = `${environment.CORE_API}api/ConfigSystem/GetConfigApp`;
      this.get<ResponseData>(url).subscribe(rs => {
        if (rs.success) {
          ConfigCoreService.moduleConfig = rs.data
        } else {
          ConfigCoreService.moduleConfig = new MyAppModule();
        }
      })
    }

    //load dropdown api
    if (!ConfigCoreService.dropDownConfig) {
        const url = `${environment.CORE_API}api/Common/DropdownKieuNgayThongKe`;
        this.get<ResponseData>(url).subscribe(rs => {
            if (rs.success) {
                if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
                ConfigCoreService.dropDownConfig.kieuNgay = rs.data.map(x => ({ id: x.id, ten: x.text }));
            } else {
               if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
                ConfigCoreService.dropDownConfig.kieuNgay = KieuNgayContact.map(x => ({ id: x.id, ten: x.ten }));
            }
        })

        const urlKieuNgayBaoCaoCuaHang = `${environment.CORE_API}api/Common/DropdownKieuNgayBaoCaoCuaHang`;
        this.get<ResponseData>(urlKieuNgayBaoCaoCuaHang).subscribe(rs => {
          if (rs.success) {
              if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
              ConfigCoreService.dropDownConfig.kieuNgayBaoCaoCuaHang = rs.data.map(x => ({ id: x.id, ten: x.text }));
          } else {
             if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
              ConfigCoreService.dropDownConfig.kieuNgayBaoCaoCuaHang = KieuNgayBaoCaoCuaHang.map(x => ({ id: x.id, ten: x.ten }));
          }
      })
    }
  }

  refreshing = false;
  dropdownConfig$: BehaviorSubject<ResponseData> = new BehaviorSubject<ResponseData>(
    null
  );

  getDropDownConfig(): Observable<DropDownApiModule> {
    if (ConfigCoreService.dropDownConfig) {
      return of(ConfigCoreService.dropDownConfig);
    } else {
      if (!this.refreshing) {
        this.refreshing = true;
        this.dropdownConfig$.next(null);

        const url = `${environment.CORE_API}api/Common/DropdownKieuNgayThongKe`;
        const urlKieuNgayBaoCaoCuaHang = `${environment.CORE_API}api/Common/DropdownKieuNgayBaoCaoCuaHang`;
        return forkJoin({
          res: this.get<ResponseData>(url),
          resKieuNgayBaoCaoCuaHang: this.get<ResponseData>(urlKieuNgayBaoCaoCuaHang),
        }).pipe(
          map(({res, resKieuNgayBaoCaoCuaHang}) => {
            this.refreshing = false;

            this.dropdownConfig$.next(res);
            if (res.success && resKieuNgayBaoCaoCuaHang.success) {
                if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
                ConfigCoreService.dropDownConfig.kieuNgay = res.data.map(x => ({ id: x.id, ten: x.text }));
                ConfigCoreService.dropDownConfig.kieuNgayBaoCaoCuaHang = res.data.map(x => ({ id: x.id, ten: x.text }));
                return ConfigCoreService.dropDownConfig;
            } else {
                if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
                ConfigCoreService.dropDownConfig.kieuNgay = KieuNgayContact.map(x => ({ id: x.id, ten: x.ten }));
                ConfigCoreService.dropDownConfig.kieuNgayBaoCaoCuaHang = res.data.map(x => ({ id: x.id, ten: x.text }));
                return ConfigCoreService.dropDownConfig;
            }
          }),
          finalize(() => {
            this.refreshing = false;
          })
        );
      } else {
        return this.dropdownConfig$.pipe(
          map((res: ResponseData) => {
            if (res.success) {
                if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
                ConfigCoreService.dropDownConfig.kieuNgay = res.data.map(x => ({ id: x.id, ten: x.text }));
                ConfigCoreService.dropDownConfig.kieuNgayBaoCaoCuaHang = res.data.map(x => ({ id: x.id, ten: x.text }));
                return ConfigCoreService.dropDownConfig;
            } else {
                if(!ConfigCoreService.dropDownConfig) ConfigCoreService.dropDownConfig = new DropDownApiModule();
                ConfigCoreService.dropDownConfig.kieuNgay = KieuNgayContact.map(x => ({ id: x.id, ten: x.ten }));
                ConfigCoreService.dropDownConfig.kieuNgayBaoCaoCuaHang = res.data.map(x => ({ id: x.id, ten: x.text }));
                return ConfigCoreService.dropDownConfig;
            }

            // if (res && res.success) {
            //   return res.data;
            // } else {
            //   return null;
            // }
          })
        );
      }
    }
  }

}
